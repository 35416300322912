import React, { useState } from "react";
import axios from "axios";
import { Form, Input, message, Row, Col } from "antd";
import LoadingScreen from "../../../lib/constants/loadingScreen";

const AddMedicalCategory = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  const addMedicalCategory = async (values) => {
    setActionLoading(true);

    const data = {
      category: values.category,
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/medical_test/category`,
        data,
        config
      );

      if (response.data.success) {
        message.success(
          "Test category added successfully",
          0.6,
          function onClose() {
            window.location.replace("/dashboard/viewmedicaltestcategory");
          }
        );
      } else {
        const errorMessage =
          response.data.error || "Failed to add category! Please try again.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to add category:", error);
      const errorMessage =
        error?.response?.data?.error ||
        "Failed to add category! Please try again.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={addMedicalCategory}
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
        >
          <div className="text-gray-800 font-medium text-3xl mb-4">
            Add Category
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: "Please input category name!" },
                ]}
              >
                <Input placeholder="Category name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <button
              id="category"
              className=" w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
              type="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddMedicalCategory;
