import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Space,
  TreeSelect,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { calculateAge } from "../../lib/constants/calculateAge";
import { useParams } from "react-router-dom";

const { Option } = Select;
const { Title } = Typography;

const UpdateTestBill = () => {
  const { billId } = useParams();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [testCategoryOptions, setTestCategoryOptions] = useState([]);
  const [testData, setTestData] = useState([]);
  const [billingTableData, setBillTableData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [form] = Form.useForm();
  const [fetchedDOB, setDOB] = useState("");
  const [selectedTest, setSelectedTest] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [editingItem, setEditingItem] = useState(null);
  const [totalGross, setTotalGross] = useState(0);
  const [totalNet, setTotalNet] = useState(0);
  let calculatedAge;

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    calculatedAge = calculateAge(fetchedDOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      form.setFieldsValue({ age: calculatedAge });
    } else {
      form.setFieldsValue({ age: null });
    }
  }, [fetchedDOB]);

  useEffect(() => {
    let grossSum = 0;
    let netSum = 0;

    billingTableData.forEach((item) => {
      grossSum += parseFloat(item.gross);
      netSum += parseFloat(item.net);
    });

    setTotalGross(grossSum);
    setTotalNet(netSum);
  }, [billingTableData]);

  useEffect(() => {
    fetchAppointment();
    fetchTestCategories();
    fetchBillDetails();
  }, []);

  const fetchBillDetails = () => {
    axios
      .get(`${apiBaseUrl}/testbill/${billId}`, config)
      .then((response) => {
        if (response.data.success) {
          form.setFieldsValue({
            appointment: response.data.data.appointment._id,
            patient: response.data.data.patient,
            userID: response.data.data.appointment.patientId?.userID,
            DOB: response.data.data.patient.DOB?.split("T")[0],
          });
          setDOB(response.data.data.patient.DOB?.split("T")[0]);

          // Map over the items array and create newItem objects
          const updatedItems = response.data.data.test.map((item) => ({
            key: item._id,
            name: item.name,
            price: item.price,
            discount: item.discount + "%",
            net: item.netAmount,
            gross: item.grossAmount,
          }));
          setBillTableData(updatedItems);
        }
      })
      .catch((error) => {
        console.error("Error fetching bill details:", error);
      });
  };

  const fetchAppointment = () => {
    axios
      .get(`${apiBaseUrl}/appointment`)
      .then((response) => {
        if (response.data.success) {
          setAppointments(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
      });
  };

  const fetchTestCategories = () => {
    axios
      .get(`${apiBaseUrl}/medical_test/categories`)
      .then((response) => {
        if (response.data.success) {
          setTestCategoryOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  };

  const fetchPatientDetails = (appointmentId) => {
    axios
      .get(`${apiBaseUrl}/appointment/${appointmentId}`)
      .then((response) => {
        if (response.data.success) {
          setDOB(response.data.data.DOB?.split("T")[0]);
          form.setFieldsValue({
            patient: response.data.data,
            DOB: response.data.data.DOB?.split("T")[0],
            userID: response.data.data.patientId.userID,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching patient details:", error);
      });
  };

  const fetchTestDetails = (categoryId) => {
    axios
      .get(`${apiBaseUrl}/lists/medical_tests?category=${categoryId}`, config)
      .then((response) => {
        if (response.data.success) {
          const formattedData = response.data.data.map((test) => ({
            title: test.name,
            price: test.price,
            value: test._id,
            children: test.subtitle.map((sub) => ({
              title: sub.name,
              value: sub._id,
              price: sub.price,
              parentName: test.name,
            })),
          }));
          setTestData(formattedData);
        }
      })
      .catch((error) => {
        console.error("Error fetching test details:", error);
      });
  };

  const handleAppointmentChange = (appointmentId) => {
    fetchPatientDetails(appointmentId);
  };
  const handleCategoryChange = (categoryId) => {
    fetchTestDetails(categoryId);
  };

  const handleTestSelect = (value, label, extra) => {
    const { triggerNode } = extra;
    const selectedPrice = triggerNode?.props?.price;
    setSelectedTest(label);
    form.setFieldsValue({ price: selectedPrice });
  };

  // const handleAddObject = () => {
  //   const values = form.getFieldsValue(["name", "price", "discount"]);
  //   if (selectedTest != null && values.price != null) {
  //     const discountPercentage = values.discount || 0;
  //     const discountedPrice = values.price * (1 - discountPercentage / 100);
  //     const netTotal = discountedPrice;
  //     const grossTotal = values.price;
  //     const newItem = {
  //       key: uuidv4(),
  //       name: selectedTest,
  //       quantity: values.quantity,
  //       price: values.price,
  //       discount: discountPercentage + "%",
  //       net: netTotal,
  //       gross: grossTotal,
  //     };
  //     setBillTableData([...billingTableData, newItem]);
  //     form.setFieldsValue({
  //       items: [...billingTableData, newItem],
  //     });
  //     form.resetFields(["name", "price", "discount"]);
  //     setSelectedTest(null);
  //   } else {
  //     message.warning("Please select name and price.");
  //   }
  // };

  const handleAddOrUpdateObject = () => {
    const values = form.getFieldsValue(["name", "price", "discount"]);
    if (selectedTest != null && values.price != null) {
      const discountPercentage = values.discount || 0;
      const discountedPrice = values.price * (1 - discountPercentage / 100);
      const netTotal = discountedPrice;
      const grossTotal = values.price;

      if (editingIndex !== null) {
        const updatedTableData = billingTableData.map((item, index) => {
          if (index === editingIndex) {
            return {
              ...item,
              name: values.name,
              price: values.price,
              discount: discountPercentage + "%",
              // quantity: values.quantity,
              net: netTotal,
              gross: grossTotal,
            };
          }
          return item;
        });
        setBillTableData(updatedTableData);
        setEditingIndex(null);
        setEditingItem(null);
      } else {
        const newItem = {
          key: uuidv4(),
          name: selectedTest,
          quantity: values.quantity,
          price: values.price,
          discount: discountPercentage + "%",
          net: netTotal,
          gross: grossTotal,
        };
        setBillTableData([...billingTableData, newItem]);
      }

      form.resetFields(["name", "price", "discount"]);
      setSelectedTest(null);
    } else {
      message.warning("Please select name and price.");
    }
  };

  const handleEditItem = (index, item) => {
    setEditingIndex(index);
    setEditingItem(item);
    form.setFieldsValue({
      name: item.name,
      price: item.price,
      discount: parseFloat(item.discount),
    });
    setSelectedTest(item.name);
  };

  const handleDeleteItem = (key) => {
    const updatedTableData = billingTableData.filter(
      (item) => item.key !== key
    );
    setBillTableData(updatedTableData);
  };

  const filterTreeNode = (inputValue, treeNode) => {
    const { title, children, parentName } = treeNode;
    if (!children) {
      return title.toLowerCase().includes(inputValue.toLowerCase());
    }
    return (
      title.toLowerCase().includes(inputValue.toLowerCase()) ||
      (parentName &&
        parentName.toLowerCase().includes(inputValue.toLowerCase()))
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record, index) => (
        <Space size="middle">
          <a onClick={() => handleEditItem(index, record)}>Edit</a>
          <a
            onClick={() => handleDeleteItem(record.key)}
            className="text-red-500 hover:text-red-600"
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    const { appointment, patient, DOB } = values;
    const { fullname, gender, mobile, address } = patient;

    const test = billingTableData.map((item) => ({
      name: item.name[0],
      price: parseInt(item.price),
      grossAmount: parseInt(item.gross),
      discount: parseInt(item.discount?.split("%")[0]),
      netAmount: parseInt(item.net),
      // discount: parseInt(item.quantity),
    }));

    const data = {
      appointment,
      test,
      patient: {
        fullname,
        DOB,
        gender,
        mobile,
        address,
      },
      // referedBy,
      totalGross,
      totalNet,
    };

    if (test.length !== 0) {
      axios
        .put(`${apiBaseUrl}/testbill/${billId}`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success(
              "Bill updated successfully!",
              0.6,
              function onClose() {
                navigate(`/dashboard/print/test_invoice/${billId}`);
              }
            );
          }
        })
        .catch((error) => {
          console.error("Error updating data:", error);
          message.error("Failed to update bill. Please try again later.");
        });
    } else {
      message.error("Medicine table cannot be empty.");
    }
  };

  return (
    <div className="bg-white rounded-md px-5 pt-4 pb-1 shadow-md">
      <div className="font-medium text-sky-600 text-4xl text-center mb-2">
        Update Bill
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Title level={3}>Patient Details</Title>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="Select Appointment"
              name="appointment"
              rules={[{ message: "Please select an appointment" }]}
            >
              <Select
                placeholder="Select an appointment"
                showSearch
                onChange={handleAppointmentChange}
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {appointments.map((appointment) => (
                  <Option key={appointment._id} value={appointment._id}>
                    {appointment.appointmentNumber}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Patient Name"
              name={["patient", "fullname"]}
              rules={[{ required: true, message: "Please enter patient name" }]}
            >
              <Input placeholder="Enter fullname" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="DOB"
              name="DOB"
              rules={[{ required: true, message: "Please select DOB" }]}
            >
              <input
                type="date"
                onChange={(e) => setDOB(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Age"
              name="age"
              rules={[
                { required: false, message: "Please select DOB for age." },
              ]}
            >
              <Input placeholder="Select DOB for age." readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="UserID"
              name="userID"
              rules={[{ message: "UserID" }]}
            >
              <Input placeholder="Empty for non-registered users" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Mobile"
              name={["patient", "mobile"]}
              rules={[
                {
                  required: true,
                  message: "Please enter patient mobile number",
                },
              ]}
            >
              <Input placeholder="Enter contact" />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              label="Gender"
              name={["patient", "gender"]}
              rules={[
                { required: true, message: "Please enter patient gender" },
              ]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Address"
              name={["patient", "address"]}
              rules={[
                { required: true, message: "Please enter patient address" },
              ]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>

        <Title level={3}>Test/ Consult</Title>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="Category"
              name="category"
              rules={[{ message: "Please select a category" }]}
            >
              <Select
                placeholder="Select a category"
                showSearch
                allowClear
                onChange={handleCategoryChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {testCategoryOptions.map((category, index) => (
                  <Option key={index} value={category._id}>
                    {category.category}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="Name"
              name="name"
              rules={[{ message: "Please select name" }]}
            >
              <TreeSelect
                treeData={testData}
                placeholder="Select a name"
                showSearch
                filterTreeNode={filterTreeNode}
                allowClear
                onChange={handleTestSelect}
                dropdownStyle={{
                  maxHeight: 400,
                  overflow: "auto",
                }}
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Price"
              name="price"
              rules={[{ message: "Please enter price" }]}
            >
              <Input type="text" placeholder="Enter price" />
            </Form.Item>
          </Col>
          <Col span={3}>
            <Form.Item label="Discount (%)" name="discount">
              <Input type="number" placeholder="Enter discount %" />
            </Form.Item>
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={handleAddOrUpdateObject}
              className="bg-gray-200 text-black rounded shadow-md"
            >
              {editingIndex !== null ? "Update" : "Add Object"}
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={billingTableData}
          pagination={false}
          summary={() => (
            <Table.Summary.Row>
              <Table.Summary.Cell colSpan={3}>
                <strong>Total</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{totalGross?.toFixed(2)}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell>
                <strong>{totalNet?.toFixed(2)}</strong>
              </Table.Summary.Cell>
              <Table.Summary.Cell />
            </Table.Summary.Row>
          )}
        />
        <Form.Item>
          <div
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              id="updateBtn"
              className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow py-2 px-4 font-semibold text-md rounded-md" // Add rounded-md className for rounded corners
              type="submit"
              style={{ width: "200px" }}
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateTestBill;
