import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Table, Input, Spin, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import { GrView } from "react-icons/gr";

const ViewLabReports = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/view/labreports`, config)
      .then((response) => {
        setDetails(response.data);
        setFilteredDetails(response.data);
      })
      .catch((error) => {
        console.error("Error fetching lab reports:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleReportDelete = async (report_id) => {
    try {
      setActionLoading(true);
      if (window.confirm("Are you sure want to delete this report?")) {
        const response = await axios.delete(
          `${apiBaseUrl}/report/delete/${report_id}`,
          config
        );
        if (response.data.success) {
          message.success(response.data.message);
          fetchData();
        } else {
          message.error(
            response?.error?.message || "Failed to delete the report."
          );
        }
      }
    } catch (e) {
      console.error("Failed to delete report:", e);
      message.error("Failed to delete! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const filterReports = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter(
          (report) =>
            report?.appointmentId?.appointmentNumber
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.patientId?.userID
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.appointmentId?.fullname
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.appointmentId?.mobile
              .toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            report?.reportName.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchData();
      }
    }, 500),
    [searchTerm, details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filterReports(searchTerm);
  };

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: ["appointmentId", "appointmentNumber"],
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: ["appointmentId", "fullname"],
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: ["appointmentId", "mobile"],
      key: "phone",
    },
    {
      title: "Report Name",
      dataIndex: "reportName",
      key: "reportName",
    },
    {
      title: "Date added",
      dataIndex: "createdAt",
      key: "date",
      render: (createdAt) => createdAt.split("T")[0],
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <>
          <Link
            to={"/dashboard/view_reportdr"}
            state={{ report: record.report }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <GrView />
            </button>
          </Link>
          <Link
            to={`/dashboard/update/report/${record._id}`}
            state={{
              patient_fullName: record.patientId.fullname,
              patient_appointmentNumber: record.appointmentId.appointmentNumber,
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-gray-600 text-white hover:bg-gray-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              handleReportDelete(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            All Reports
          </h1>
          <div className="my-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ViewLabReports;
