import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import { IconButton } from "@mui/material";
import { HiPlusCircle } from "react-icons/hi";
import { AiFillMinusCircle } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoadingScreen from "../lib/constants/loadingScreen";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const AddAppointmentTime = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [timeList, setTimeList] = useState([""]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [doctorAndDateDetails, setDateAndDoctorDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState("");
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
    }
  };

  // Time form field functions....
  const addTime = () => {
    setTimeList([...timeList, ""]);
  };

  const removeTime = (index) => {
    const updatedList = [...timeList];
    updatedList.splice(index, 1);
    setTimeList(updatedList);
  };

  const handleInputChange = (index, time) => {
    const updatedList = [...timeList];
    updatedList[index] = time;
    setTimeList(updatedList);
  };

  const handleTimeChange = (index, selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    handleInputChange(index, formattedTime);
  };

  // Date Picker
  const handleDateChange = (date) => {
    // Get the UTC date string with zero offset
    const formattedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    )
      .toISOString()
      .split("T")[0];
    setSelectedDate(formattedDate);
  };

  const currentDate = new Date(); // Get current date
  currentDate.setHours(0, 0, 0, 0);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/view_appointment/datetime`, config)
      .then((response) => {
        setDateAndDoctorDetails(response.data);
      })
      .catch((e) => {
        console.error("Failed to get appointment details", e);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  // Assume isAppointmentExists function checks if the appointment exists for a given doctorId and date
  const isDoctorDateDisabled = (date) => {
    const formattedDate = formatDate(date);
    console.log("Formatted Date:", formattedDate);
    const hasAppointment = doctorAndDateDetails.some((item) => {
      return (
        item.doctorId._id === doctorSelectedId && item.date === formattedDate
      );
    });
    console.log("Has Appointment:", hasAppointment);
    return !hasAppointment;
  };

  const addAppointmentTime = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      doctorId: doctorSelectedId,
      date: selectedDate,
      time: timeList,
    };

    axios
      .post(`${apiBaseUrl}/staff/appointment/dateAndtime`, data, config)
      .then((response) => {
        if (response.data.success === true) {
          message.success(
            "Appointment Date Time added",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/view_appointment/datetime");
            }
          );
        } else if (response.data.msg === "Already exist DateAndTime") {
          message.error("DateTime already exists");
        } else {
          message.error("Please select valid doctor");
        }
      })
      .catch((error) => {
        console.error("Failed to add appointment date & time:", error);
        message.error(
          "Failed to add appointment date & time! Please try again."
        );
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={addAppointmentTime}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Add Appointment DateTime</Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={departmentStoringValue}
                onChange={handleDepartmentInputChange}
                isSearchable
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={doctorStoringValue}
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>
          <label className="text-base -mb-4">Date</label>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM-dd"
            placeholderText="Select a date"
            className="border-solid border-gray-300 border py-2 px-3 rounded-md text-gray-700 appearance-none w-full"
            popperClassName="mt-2 rounded-lg shadow-lg"
            calendarClassName="pb-2 bg-white rounded-lg shadow-lg border border-gray-300"
            minDate={currentDate}
            filterDate={isDoctorDateDisabled}
            readOnlyonKeyDown={(e) => e.preventDefault()}
            onFocus={(e) => e.target.blur()}
          />

          {timeList.map((item, index) => (
            <div className="flex items-center" key={index}>
              <div className="w-full mr-4">
                <label
                  className="text-base"
                  htmlFor={`appointment-time-${index}`}
                >
                  Time
                </label>
                <Flatpickr
                  data-enable-time
                  data-disable-date
                  value={item}
                  placeholder="Pick a time"
                  onChange={(selectedDates, dateStr, flatpickrInstance) =>
                    handleTimeChange(
                      index,
                      selectedDates,
                      dateStr,
                      flatpickrInstance
                    )
                  }
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500 transition-colors duration-200"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "h:i K",
                    time_24hr: false,
                  }}
                />
              </div>
              <div className="flex items-center">
                <IconButton
                  onClick={() => removeTime(index)}
                  color="danger"
                  aria-label="minus button"
                  className="text-red-500 hover:text-red-700 transition-colors duration-200"
                >
                  <AiFillMinusCircle />
                </IconButton>
              </div>
            </div>
          ))}
          <IconButton onClick={addTime} color="primary" aria-label="add button">
            Add time field
            <HiPlusCircle />
          </IconButton>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit" //htmlType
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default AddAppointmentTime;
