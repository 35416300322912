import React, { useState, useEffect } from "react";
import { Button } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { PhoneOutlined, EnvironmentOutlined } from "@ant-design/icons";
import "./printStyle.css";
import moment from "moment-timezone";
import { calculateAge } from "../../lib/constants/calculateAge";

const PrintInvoice = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { billID } = useParams();
  const [tableData, setTableData] = useState([]);
  const [DOB, setDOB] = useState("");
  const [age, setAge] = useState("");
  const [medicineList, setMedicineList] = useState([]);
  let calculatedAge;

  console.log("AGE:", age);

  useEffect(() => {
    calculatedAge = calculateAge(DOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      setAge(calculatedAge);
    } else {
      setAge("");
    }
  }, [DOB]);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/bills/${billID}`)
      .then((response) => {
        if (response.data.success) {
          console.log(response.data.data.items);
          console.log(response.data.data);
          setTableData(response.data.data);
          setDOB(response.data.data.patient?.DOB?.split("T")[0]);
          setMedicineList(response.data.data.items);
        }
      })
      .catch((error) => {
        console.error("Error fetching table data:", error);
      });
  }, [billID]);

  const handlePrint = () => {
    const printableContent =
      document.getElementById("printableContent").innerHTML;

    // Create a temporary div element
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = printableContent;
    // Replace the body content with the printable content
    document.body.innerHTML = tempDiv.innerHTML;

    window.print();
    window.location.replace("/dashboard/bills");
  };

  const convertToNepaliTime = (utcTime) => {
    return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
  };

  return (
    <div>
      <div id="printableContent" className="print-content">
        <div className="container p-4 place-content-center">
          <div className="mb-3">
            <div className="text-center">
              <span className="text-2xl">Tulsi MultiSpeciality Clinic</span>
              <p>
                <EnvironmentOutlined /> Avm chowk 05, Lalitpur 44600
              </p>
              <p>
                <PhoneOutlined /> 01-5914294
              </p>
            </div>
          </div>

          <div className="flex justify-between mb-8">
            <div className="flex flex-col text-start">
              <p>Patient ID: {tableData?.appointment?.patientId?.userID}</p>
              <p>Name: {tableData?.patient?.fullname}</p>
              <p>
                Age/ Sex: {age}
                {"/"} {tableData?.patient?.gender}
              </p>
              <p>Address: {tableData?.patient?.address}</p>
            </div>
            <div className="flex flex-col text-end">
              <p>Appointment No: {tableData?.appointment?.appointmentNumber}</p>
              <p>Reg. Number: {tableData?.regNo}</p>

              <p>
                BillID/Date: {tableData?.billNo} /{" "}
                {convertToNepaliTime(tableData?.billDate)}
              </p>
            </div>
          </div>

          <table className="w-full border-collapse">
            <thead>
              <tr>
                <th className="border-t w-2/5 border-b border-black px-4 py-2 text-start font-semibold">
                  Particular
                </th>
                <th className="border-t border-b border-black px-4 py-2 text-end font-semibold">
                  Qty
                </th>
                <th className="border-t border-b border-black px-4 py-2 text-end font-semibold">
                  Price/Qty
                </th>
                <th className="border-t border-b border-black px-4 py-2 text-end font-semibold">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {medicineList.map((item, index) => (
                <tr key={index}>
                  <td className="px-4 py-1 text-start">{item.medicine.name}</td>
                  <td className="px-4 py-1 text-end">{item.quantity}</td>
                  <td className="px-4 py-1 text-end">
                    Rs. {item.grossAmount?.toFixed(2)}
                  </td>
                  <td className="px-4 py-1 text-end">
                    Rs. {item.netAmount?.toFixed(2)}
                  </td>
                </tr>
              ))}
              <tr>
                <td
                  colSpan="3"
                  className="border-t border-black px-4 py-1 text-end"
                >
                  Paid At
                </td>
                <td className="border-t border-black px-4 py-1 text-end">
                  {convertToNepaliTime(tableData?.paidAt)}
                </td>
              </tr>
              <tr className="border-b border-black ">
                <td colSpan="3" className="px-4 py-1 text-end">
                  Total Amount
                </td>
                <td className="border-b border-black px-4 py-1 text-end">
                  Rs. {tableData?.totalNet?.toFixed(2)}
                </td>
              </tr>
            </tbody>
          </table>

          <div className="generated-by text-end me-5 mt-3">
            <p>User: {tableData?.generatedBy?.username}</p>
          </div>
        </div>
      </div>
      {/* Print Button */}
      <Button
        type="primary"
        onClick={handlePrint}
        className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow font-semibold text-md rounded-md"
      >
        Print Invoice
      </Button>
    </div>
  );
};

export default PrintInvoice;
