import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Table,
  Space,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import axios from "axios";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { calculateAge } from "../../lib/constants/calculateAge";

const { Option } = Select;
const { Title } = Typography;

const UpdateBill = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { billID } = useParams();
  const navigate = useNavigate();
  const [medicineOptions, setMedicineOptions] = useState([]);
  const [medicineTableData, setMedicineTableData] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState("");
  const [selectedPatient, setSelectedPatient] = useState({});
  const [form] = Form.useForm();
  const [fetchedDOB, setDOB] = useState("");
  let calculatedAge;

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  useEffect(() => {
    calculatedAge = calculateAge(fetchedDOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      form.setFieldsValue({ age: calculatedAge });
    } else {
      form.setFieldsValue({ age: null });
    }
  }, [fetchedDOB]);

  useEffect(() => {
    // Fetch appointments data
    axios
      .get(`${apiBaseUrl}/bills/${billID}`)
      .then((response) => {
        if (response.data.success) {
          form.setFieldsValue({
            appointment: response.data.data.appointment._id,
            patient: response.data.data.patient,
            userID: response.data.data.appointment.patientId,
            DOB: response.data.data.patient.DOB?.split("T")[0],
          });
          setDOB(response.data.data.patient.DOB?.split("T")[0]);

          console.log(response.data.data);

          // Map over the items array and create newItem objects
          const updatedItems = response.data.data.items.map((item) => ({
            key: item._id,
            medicineId: item.medicine._id,
            medicine: item.medicine.name,
            quantity: item.quantity,
            price: item.price,
            discount: item.discount + "%",

            net: item.netAmount,
            gross: item.grossAmount,
          }));
          console.log(updatedItems);
          setMedicineTableData(updatedItems);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/appointment`)
      .then((response) => {
        if (response.data.success) {
          setAppointments(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/medicine`)
      .then((response) => {
        if (response.data.success) {
          setMedicineOptions(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medicine:", error);
      });
  }, []);

  const fetchPatientDetails = (appointmentId) => {
    axios
      .get(`${apiBaseUrl}/appointment/${appointmentId}`)
      .then((response) => {
        if (response.data.success) {
          setSelectedPatient(response.data.data);
          setDOB(response.data.data.DOB?.split("T")[0]);
          form.setFieldsValue({
            patient: response.data.data,
            DOB: response.data.data.DOB?.split("T")[0],
            userID: response.data.data.patientId,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching patient details:", error);
      });
  };

  const handleAppointmentChange = (appointmentId) => {
    setSelectedAppointment(appointmentId);
    fetchPatientDetails(appointmentId);

    form.setFieldsValue({
      appointment: appointmentId,
      appointmentId: appointmentId,
    });
  };

  const handleAddMedicine = () => {
    const values = form.getFieldsValue(["medicine", "quantity", "price", "discount"]);
    if (values.medicine != null && values.quantity != null) {
      const selectedMedicine = medicineOptions.find(
        (med) => med._id === values.medicine
      );
      const discountPercentage = values.discount || 0;
      const discountedPrice =
        selectedMedicine.price * (1 - discountPercentage / 100);
      const netTotal = discountedPrice * values.quantity;
      const grossTotal = selectedMedicine.price * values.quantity;
      const newItem = {
        key: selectedMedicine._id,
        medicineId: selectedMedicine._id,
        medicine: selectedMedicine.name,
        quantity: values.quantity,
        price: selectedMedicine.price,
        discount: discountPercentage + "%",
        net: netTotal?.toFixed(2),
        gross: grossTotal?.toFixed(2),
      };
      setMedicineTableData([...medicineTableData, newItem]);

      form.setFieldsValue({
        items: [...medicineTableData, newItem],
      });
      form.resetFields(["medicine", "quantity", "price", "discount"]);
    } else {
      message.warning("Please select a medicine and quantity.");
    }
  };

  const handleDeleteMedicine = (key) => {
    const updatedTableData = medicineTableData.filter(
      (item) => item.key !== key
    );
    setMedicineTableData(updatedTableData);
  };

  const columns = [
    {
      title: "Medicine",
      dataIndex: "medicine",
      key: "medicine",
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Price/quantity",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Discount (%)",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Gross",
      dataIndex: "gross",
      key: "gross",
    },
    {
      title: "Net",
      dataIndex: "net",
      key: "net",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <a
            onClick={() => handleDeleteMedicine(record.key)}
            className="text-red-500 hover:text-red-600"
          >
            Delete
          </a>
        </Space>
      ),
    },
  ];

  const onFinish = (values) => {
    const { appointment, DOB, patient } = values;
    const { fullname, gender, mobile, address } = patient;

    const items = medicineTableData.map((item) => ({
      medicine: item.medicineId,
      quantity: parseInt(item.quantity),
      price: parseInt(item.price),
      grossAmount: parseInt(item.gross),
      discount: parseInt(item.discount?.split("%")[0]),
      netAmount: parseInt(item.net),
    }));

    const data = {
      appointment,
      items,
      patient: {
        fullname,
        DOB,
        gender,
        mobile,
        address,
      },
    };

    if (items.length !== 0) {
      axios
        .put(`${apiBaseUrl}/bills/${billID}`, data, config)
        .then((response) => {
          if (response.data.success) {
            message.success("Bill Updated successfully!");
            navigate(
              `/dashboard/pharmacy/print-invoice/${response.data.data._id}`
            );
          }
        })
        .catch((error) => {
          console.error("Error posting data:", error);
          message.error("Failed to update bill. Please try again later.");
        });
    } else {
      message.error("Medicine table cannot be empty.");
    }
  };

  return (
    <div className="bg-white rounded-md p-5 shadow-md">
      <div className="font-medium text-sky-600 text-4xl text-center mb-2">
        Update Medicine Bill
      </div>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Title level={3}>Patient Details</Title>
        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="Select Appointment"
              name="appointment"
              rules={[{ message: "Please select an appointment" }]}
            >
              <Select
                placeholder="Select an appointment"
                showSearch
                allowClear
                onChange={handleAppointmentChange}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {appointments.map((appointment) => (
                  <Option key={appointment._id} value={appointment._id}>
                    {appointment.appointmentNumber}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label="Patient Name"
              name={["patient", "fullname"]}
              rules={[{ required: true, message: "Please enter patient name" }]}
            >
              <Input placeholder="Enter fullname" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="DOB"
              name="DOB"
              rules={[{ required: true, message: "Please select DOB" }]}
            >
              <input
                type="date"
                onChange={(e) => setDOB(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Age"
              name="age"
              rules={[
                { required: false, message: "Please select DOB for age." },
              ]}
            >
              <Input placeholder="Select DOB for age." readOnly />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={4}>
            <Form.Item
              label="UserID"
              name={["userID", "userID"]}
              rules={[{ message: "UserID" }]}
            >
              <Input placeholder="Empty for non-registered users" allowClear />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Mobile"
              name={["patient", "mobile"]}
              rules={[
                {
                  required: true,
                  message: "Please enter patient mobile number",
                },
              ]}
            >
              <Input placeholder="Enter contact" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Gender"
              name={["patient", "gender"]}
              rules={[
                { required: true, message: "Please enter patient gender" },
              ]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              label="Address"
              name={["patient", "address"]}
              rules={[
                { required: true, message: "Please enter patient address" },
              ]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
        </Row>

        <Title level={3}>Medicine</Title>
        <Row gutter={[16, 16]}>
          <Col span={6}>
            <Form.Item
              label="Medicine"
              name="medicine"
              rules={[{ message: "Please select a medicine" }]}
            >
              <Select
                placeholder="Select a medicine"
                onChange={
                  (value) => {
                    const selectedMedicine = medicineOptions.find(
                      (med) => med._id === value
                    );
                    form.setFieldsValue({
                      price: selectedMedicine.price?.toFixed(2),
                    })
                  }
                }
                showSearch
                allowClear
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {medicineOptions.map((med, index) => (
                  <Option key={index} value={med._id}>
                    {med.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ message: "Please enter quantity" }]}
            >
              <Input type="number" placeholder="Enter medicine quantity" />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Price" name="price">
              <Input type="number" placeholder="Enter Price" readOnly />
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item label="Discount (%)" name="discount">
              <Input type="number" placeholder="Enter discount percentage" />
            </Form.Item>
          </Col>
          <Col span={4} style={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={handleAddMedicine}
              className="bg-gray-200 text-black rounded shadow-md"
            >
              Add Medicine
            </Button>
          </Col>
        </Row>

        <Table
          columns={columns}
          dataSource={medicineTableData}
          pagination={false}
        />

        <Form.Item>
          <div
            style={{ display: "flex", justifyContent: "start", width: "100%" }}
          >
            <button
              id="addStaffBtn"
              className="mt-4 bg-sky-600 hover:bg-sky-700 text-white border shadow py-3 px-6 font-semibold text-md rounded-md" // Add rounded-md className for rounded corners
              type="submit"
              style={{ width: "200px" }}
            >
              Submit
            </button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default UpdateBill;
