import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { IoRibbonOutline, IoPieChart, IoPeople, IoCart } from "react-icons/io5";

export default function DashboardStatsGrid() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [totalAppointments, setTotalAppointments] = useState(0);
  const [thisMonthAppointment, setThisMonthAppointment] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/appointment`)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;
          setTotalAppointments(data.length);

          // Counting current month appointments
          const currentDate = new Date();
          const currentMonth = currentDate.getMonth() + 1;
          const currentYear = currentDate.getFullYear();

          const appointmentsThisMonth = data.filter((appointment) => {
            const createdAtDate = new Date(appointment.createdAt);
            const createdAtMonth = createdAtDate.getMonth() + 1;
            const createdAtYear = createdAtDate.getFullYear();
            return (
              createdAtMonth === currentMonth && createdAtYear === currentYear
            );
          });
          const appointmentsCountThisMonth = appointmentsThisMonth.length;
          setThisMonthAppointment(appointmentsCountThisMonth);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
        message.error("Failed to get appointment details! Please try again.");
      });

    axios
      .get(`${apiBaseUrl}/view/patients`, config)
      .then((response) => {
        if (response.data.success) {
          const data = response.data.data;
          setTotalUsers(data.length);
        }
      })
      .catch((error) => {
        console.error("Error fetching appointments:", error);
        message.error("Failed to get appointment details! Please try again.");
      });
  }, []);

  return (
    <div className="flex flex-col gap-4">
      <BoxWrapper>
        <div className="rounded-full h-12 w-12 flex items-center justify-center bg-sky-500">
          <IoRibbonOutline className="text-2xl text-white" />
        </div>
        <div className="pl-4">
          <span className="text-sm text-gray-600 font-normal">
            Total Appointments
          </span>
          <div className="flex items-center">
            <strong className="text-xl text-gray-700 font-semibold">
              {totalAppointments}
            </strong>
          </div>
        </div>
      </BoxWrapper>
      <BoxWrapper>
        <div className="rounded-full h-12 w-12 flex items-center justify-center bg-orange-600">
          <IoPieChart className="text-2xl text-white" />
        </div>
        <div className="pl-4">
          <span className="text-sm text-gray-500 font-light">
            This Month Appointments
          </span>
          <div className="flex items-center">
            <strong className="text-xl text-gray-700 font-semibold">
              {thisMonthAppointment}
            </strong>
          </div>
        </div>
      </BoxWrapper>
      <BoxWrapper>
        <div className="rounded-full h-12 w-12 flex items-center justify-center bg-yellow-400">
          <IoPeople className="text-2xl text-white" />
        </div>
        <div className="pl-4">
          <span className="text-sm text-gray-500 font-light">Total Users</span>
          <div className="flex items-center">
            <strong className="text-xl text-gray-700 font-semibold">
              {totalUsers}
            </strong>
          </div>
        </div>
      </BoxWrapper>
    </div>
  );
}
function BoxWrapper({ children }) {
  return (
    <div className="bg-white rounded-sm p-4 flex-1 border border-gray-200 flex items-center">
      {children}
    </div>
  );
}
