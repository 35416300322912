import React, { useEffect, useState } from "react";
import axios from "axios";

import { Form, Input, Select, message, Row, Col, Typography } from "antd";
import LoadingScreen from "../lib/constants/loadingScreen";
import { useParams } from "react-router-dom";

const { Option } = Select;
const { Title } = Typography;

const UpdateLabReporter = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { userId } = useParams();
  const [form] = Form.useForm();
  const [DOB, setDOB] = useState("");
  const [loading, setLoading] = useState("");
  const [imageURL, setImageURL] = useState(null);
  const [actionLoading, setactionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/labreporter/${userId}`, config)
      .then((response) => {
        if (response.data.success) {
          const staff = response.data.data;
          const fetchDOB = response.data.data.DOB;
          const extractedDOB = fetchDOB?.split("T")[0];
          form.setFieldsValue({
            fullname: staff.fullname,
            phone: staff.phone,
            email: staff.email,
            address: staff.address,
            gender: staff.gender,
            username: staff.username,
          });
          setImageURL(staff.picture);
          setDOB(extractedDOB);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching doctor details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching department", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching doctor details.";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    setImageURL(URL.createObjectURL(file));
    form.setFieldsValue({ photos: file });
  };

  const registerStaff = (values) => {
    setactionLoading(true);

    const data = new FormData();
    data.append("fullname", values.fullname);
    data.append("gender", values.gender);
    data.append("username", values.username);
    data.append("DOB", DOB);
    data.append("email", values.email);
    data.append("phone", values.phone);
    data.append("address", values.address);
    data.append("photo", values.photos);

    axios
      .put(`${apiBaseUrl}/labreporter/update/${userId}`, data, config)
      .then((response) => {
        if (response.data.success) {
          message.success(
            "Lab-reporter updated successfully",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/viewlabreporter");
            }
          );
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while updating the lab-reporter.";
          message.error(errorMessage);
        }
      })
      .catch((err) => {
        console.error("Failed to update lab-reporter:", err);
        const errorMessage =
          err.response?.data?.error ||
          err.message ||
          "An unexpected error occurred while updating the lab-reporter.";
        message.error(errorMessage);
      })

      .finally(() => {
        setactionLoading(false);
      });
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <Form
        form={form}
        onFinish={registerStaff}
        layout="vertical"
        className="forbox w-full bg-white max-w-3xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Update Lab-Reporter</Title>
        <Row gutter={[16, 16]}>
          <Col span={10}>
            <Form.Item
              label="Fullname"
              name="fullname"
              rules={[{ required: true, message: "Please enter full name" }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true, message: "Please enter username" }]}
            >
              <Input placeholder="Enter username" />
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Please enter phone number" }]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Email address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                { required: true, message: "Please enter email address" },
              ]}
            >
              <Input placeholder="Enter email address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="DOB"
              rules={[
                {
                  message: "Please enter a valid email address",
                },
                { required: false, message: "Please enter email address" },
              ]}
            >
              <input
                id="DOB"
                value={DOB}
                type="date"
                onChange={(e) => {
                  setDOB(e.target.value);
                }}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please enter address" }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label="Image"
          name="photos"
          rules={[{ required: false, message: "Please upload an image" }]}
        >
          <input
            onChange={handlePictureChange}
            type="file"
            className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
          />
          {imageURL && (
            <div>
              <img
                src={imageURL}
                alt="Doctor Preview"
                className="mt-4 rounded-lg max-h-40"
              />
            </div>
          )}
        </Form.Item>

        <Form.Item>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit" //htmlType
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default UpdateLabReporter;
