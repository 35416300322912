import React from "react";
import BottomComponent from "./bottomComponent";
import TopComponentChart from "./topComponentChart";
import QuickAccessComponent from "./QuickAccessComponent";
import DashboardStatsGrid from "./DashboardStatsGrid";

export default function Dashboard() {
  if (localStorage.getItem("adminticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 w-full">
          <TopComponentChart />
          <div className="w-[20rem] h-[22rem]">
            <DashboardStatsGrid />
          </div>
        </div>
        <div className="flex flex-row gap-4 w-full">
          <BottomComponent />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("doctorticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 w-full">
          <TopComponentChart />
          <QuickAccessComponent />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <BottomComponent />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("staffticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 w-full">
          <TopComponentChart />
          <QuickAccessComponent />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <BottomComponent />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("labreporterticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 w-full">
          <TopComponentChart />
          <QuickAccessComponent />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <BottomComponent />
        </div>
      </div>
    );
  }
  if (localStorage.getItem("pharmaticket")) {
    return (
      <div id="dashboard" className="flex flex-col gap-4">
        <div className="flex flex-row gap-4 w-full">
          <TopComponentChart />
          <QuickAccessComponent />
        </div>
        <div className="flex flex-row gap-4 w-full">
          <BottomComponent />
        </div>
      </div>
    );
  } else {
    window.location.replace("/");
  }
}
