import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Image, Input, Spin, message, DatePicker, Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import moment from "moment-timezone";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { GrView } from "react-icons/gr";
import { FaPrint } from "react-icons/fa6";
const { RangePicker } = DatePicker;

const ViewLabBillsPage = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/testbill`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error(e);
        message.error("Failed to fetch staffs! Please try again later.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const filteredStaff = useCallback(
    debounce((searchTerm, dateRange) => {
      let filtered = details;
      if (searchTerm.trim() !== "") {
        filtered = filtered.filter(
          (bill) =>
            bill?.billNo?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            bill?.patient?.mobile
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            bill?.patient?.fullname
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase())
        );
      }
      if (dateRange && dateRange[0] && dateRange[1]) {
        const [startDate, endDate] = dateRange.map((date) =>
          date.format("YYYY-MM-DD")
        );
        filtered = filtered.filter((date) => {
          const billingDate = moment
            .utc(date.billDate)
            .tz("Asia/Kathmandu")
            .format("YYYY-MM-DD");
          return billingDate >= startDate && billingDate <= endDate;
        });
      }
      setFilteredDetails(filtered);
    }, 500),
    [details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filteredStaff(searchTerm, dateRange);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates || [null, null]);
    filteredStaff(searchTerm, dates);
  };

  const handleResetFilters = () => {
    setSearchTerm("");
    setDateRange([null, null]);
    setFilteredDetails(details);
  };

  const convertToNepaliTime = (utcTime) => {
    return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
  };

  const columns = [
    {
      title: "BillNo",
      dataIndex: "billNo",
      key: "billNo",
    },
    {
      title: "Patient Name",
      dataIndex: ["patient", "fullname"],
      key: "fullname",
    },
    {
      title: "DOB",
      dataIndex: ["patient", "DOB"],
      key: "DOB",
      render: (DOB) => (DOB ? DOB.split("T")[0] : "-"),
    },
    {
      title: "Contact",
      dataIndex: ["patient", "mobile"],
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: ["patient", "address"],
      key: "address",
    },
    {
      title: "Total Amount",
      dataIndex: "totalNet",
      key: "totalNet",
    },
    {
      title: "Bill Date",
      dataIndex: "billDate",
      key: "billDate",
      render: (billDate) => {
        const nepaliTime = convertToNepaliTime(billDate);
        return <span>{nepaliTime}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      width: 200,
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/get/detailedbill/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <GrView />
            </button>
          </Link>
          <Link
            to={`/dashboard/update/testbill/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-gray-600 text-white hover:bg-gray-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            to={`/dashboard/print/test_invoice/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 bg-gray-50 border hover:bg-gray-100 hover:text-black text-black font-bold rounded-md shadow-md">
              <FaPrint />
            </button>
          </Link>
        </>
      ),
    },
  ];
  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="container max-w-8xl mx-auto" id="viewStaff">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Billing Archives
          </h1>
          <div className="flex justify-between mt-3">
            <div>
              <Input
                placeholder="Search..."
                prefix={<SearchOutlined />}
                allowClear
                value={searchTerm}
                onChange={handleSearch}
                style={{ width: 250 }}
              />
              <RangePicker
                style={{ marginLeft: "1rem" }}
                format="YYYY-MM-DD"
                value={dateRange}
                onChange={handleDateChange}
              />
              <Button
                type="default"
                onClick={handleResetFilters}
                style={{ marginLeft: "1rem" }}
              >
                Reset Filters
              </Button>
            </div>
            <div>
              <Link to="/dashboard/add/testbill">
                <button className="px-4 py-1.5 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                  Create Bill
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ViewLabBillsPage;
