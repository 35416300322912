import { Spin } from "antd";

const LoadingScreen = () => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-65">
        <Spin tip="Loading..." size="large" />
      </div>
    </>
  );
};

export default LoadingScreen;
