export function calculateAge(dateOfBirth) {
  const today = new Date();
  const birthDate = new Date(dateOfBirth);

  if (isNaN(birthDate.getTime())) {
    return "Invalid date of birth";
  }

  let ageInYears = today.getFullYear() - birthDate.getFullYear();
  let ageInMonths = today.getMonth() - birthDate.getMonth();
  let ageInDays = today.getDate() - birthDate.getDate();

  // Adjust the age if the birthday hasn't occurred yet this year
  if (ageInMonths < 0 || (ageInMonths === 0 && ageInDays < 0)) {
    ageInYears--;
    ageInMonths += 12;
  }

  // Handle negative values for age in days
  if (ageInDays < 0) {
    const lastMonthDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      0
    ).getDate();
    ageInDays += lastMonthDate;
    ageInMonths--;
  }

  // if (ageInYears === 0 && ageInMonths === 0) {
  //   return `${ageInDays} day${ageInDays !== 1 ? "s" : ""}`;
  // } else if (ageInYears === 0) {
  //   return `${ageInMonths} month${ageInMonths !== 1 ? "s" : ""}`;
  // } else if (ageInYears < 7) {
  //   const adjustedYears = ageInYears + (ageInMonths >= 10 ? 1 : 0);
  //   return `${adjustedYears} year${adjustedYears !== 1 ? "s" : ""}`;
  // } else {
  //   return `${ageInYears} year${ageInYears !== 1 ? "s" : ""}`;
  // }

  if (ageInYears === 0 && ageInMonths === 0) {
    return `${ageInDays} day${ageInDays !== 1 ? "s" : ""}`;
  } else if (ageInYears === 0) {
    return `${ageInMonths} month${
      ageInMonths !== 1 ? "s" : ""
    }, ${ageInDays} day${ageInDays !== 1 ? "s" : ""}`;
  } else {
    return `${ageInYears} year${
      ageInYears !== 1 ? "s" : ""
    }, ${ageInMonths} month${ageInMonths !== 1 ? "s" : ""}`;
  }
}
