import React, { useEffect, useState } from "react";
import axios from "axios";
import Link from "antd/es/typography/Link";
import LoadingScreen from "../lib/constants/loadingScreen";
import { Form, Input, Select, message, Row, Col, Typography } from "antd";
import { calculateAge } from "../lib/constants/calculateAge";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "./placeholder.css";

const { Option } = Select;
const { Title } = Typography;

const AddPatient = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [DOB, setDOBinAD] = useState("");
  const [actionLoading, setActionLoading] = useState(false);
  const [isDOBMatched, setIsDOBMatched] = useState(false);
  const [dateBS, setDateBS] = useState("");
  const [dateAD, setDateAD] = useState("");

  let calculatedAge;

  useEffect(() => {
    calculatedAge = calculateAge(DOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      form.setFieldsValue({ age: calculatedAge });

      if (dateAD === DOB) {
        setIsDOBMatched(true);
      } else {
        setIsDOBMatched(false);
      }
    } else {
      form.setFieldsValue({ age: null });
    }
  }, [DOB, dateAD]);

  const dateBStoAD = () => {
    if (dateAD) {
      setDOBinAD(dateAD);
    } else {
      form.setFieldsValue({ age: null });
    }
  };

  const registerPatient = async (values) => {
    setActionLoading(true);

    const data = {
      fullname: values.fullname,
      phone: values.phone,
      address: values.address,
      gender: values.gender,
      DOB,
      DOBinBS: dateBS,
      // age: values.age,
    };

    try {
      const response = await axios.post(`${apiBaseUrl}/patient/insert`, data);
      if (response.data.success) {
        message.success("Patient added successfully", 0.6, function onClose() {
          window.location.replace("/dashboard/viewuser");
        });
      } else {
        const errorMessage =
          response.data.error ||
          "An error occurred while registering the patient.";
        message.error(errorMessage);
      }
    } catch (err) {
      console.error("Failed to register staff:", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "An unexpected error occurred while registering the staff.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  const handleDateInBS = ({ bsDate, adDate }) => {
    setDateBS(bsDate);
    setDateAD(adDate);
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <Form
        form={form}
        onFinish={registerPatient}
        layout="vertical"
        className="forbox w-full bg-white max-w-3xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Add Patient</Title>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Fullname"
              name="fullname"
              rules={[{ required: true, message: "Please enter full name" }]}
            >
              <Input placeholder="Enter full name" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="DOB (in BS)"
              name="dateInAD"
              rules={[{ required: false, message: "Please select Date" }]}
            >
              <Calendar
                onChange={handleDateInBS}
                theme="blue"
                hideDefaultValue="true"
                language="en"
                placeholder="Select Date(BS)"
                className="border border-gray-300 border py-1 px-3 rounded-md text-gray-800 custom-placeholder w-full"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Phone"
              name="phone"
              rules={[{ required: true, message: "Please enter phone number" }]}
            >
              <Input placeholder="Enter phone number" />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="DOB (in AD)"
              rules={[{ required: true, message: "Please enter DOB (inAD)" }]}
            >
              <input
                id="DOB"
                value={DOB}
                type="date"
                onChange={(e) => {
                  setDOBinAD(e.target.value);
                }}
                max={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                name="dob"
                required
                placeholder="Select Date"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="Age"
              name="age"
              rules={[{ required: true, message: "Please enter age" }]}
            >
              <Input placeholder="Age" type="text" readOnly />
            </Form.Item>
          </Col>
        </Row>
        {!isDOBMatched && (
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div className="-mt-5 mb-3 flex justify-end">
                <p className="text-red-500 italic me-2">
                  The selected DOB(in BS) does not match the DOB(in AD).
                </p>
                <Link
                  onClick={dateBStoAD}
                  className="text-blue-500 italic border-b hover:text-blue-600 font-semibold inline-flex items-center"
                >
                  Click here to correct it.
                </Link>
              </div>
            </Col>
          </Row>
        )}
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: true, message: "Please enter address" }]}
            >
              <Input placeholder="Enter address" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "Please select gender" }]}
            >
              <Select placeholder="Select gender">
                <Option value="Male">Male</Option>
                <Option value="Female">Female</Option>
                <Option value="Others">Others</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit" //htmlType
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default AddPatient;
