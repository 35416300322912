import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, message, Row, Select, Col, Button } from "antd";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { CloseOutlined } from "@ant-design/icons";

const { Option } = Select;

const AddMedicalTestList = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [testCategory, setTestCategory] = useState([]);
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/medical_test/categories`, config)
      .then((response) => {
        if (response.data.success) {
          setTestCategory(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching test categories:", error);
        message.error("Error fetching test categories! Please try again.");
      });
  }, []);

  const addMedicalTest = async (values) => {
    const { category, name, price, subtitle } = values;
    setActionLoading(true);

    console.log(values);

    const data = {
      category,
      name,
      price,
      subtitle,
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/medical_test_list`,
        data,
        config
      );

      if (response.data.success) {
        message.success(
          "Medical test added successfully",
          0.6,
          function onClose() {
            window.location.replace("/dashboard/viewmedicaltestlists");
          }
        );
      } else {
        const errorMessage =
          response.data.error ||
          "Failed to add medical test! Please try again.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to add medical test:", error);
      const errorMessage =
        error?.response?.data?.error ||
        "Failed to add medical test! Please try again.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={addMedicalTest}
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
        >
          <div className="text-gray-800 font-medium text-3xl mb-4">
            Add Test
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Category"
                name="category"
                rules={[
                  { required: true, message: "Please select a category!" },
                ]}
              >
                <Select
                  placeholder="Select a category"
                  showSearch
                  allowClear
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {testCategory.map((category) => (
                    <Option key={category._id} value={category._id}>
                      {category.category}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Please input category name." },
                ]}
              >
                <Input placeholder="Name" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Price"
                name="price"
                rules={[{ required: false, message: "Please enter price." }]}
              >
                <Input placeholder="Price (Keep it empty if subtitle)" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item label="Subtitle">
                <Form.List name="subtitle">
                  {(fields, { add, remove }) => (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: 16,
                      }}
                    >
                      {fields.map((field) => (
                        <div key={field.key}>
                          <Row gutter={[16, 16]}>
                            <Col span={12}>
                              <Form.Item
                                name={[field.name, "name"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter subtitle.",
                                  },
                                ]}
                              >
                                <Input placeholder="Subtitle name" />
                              </Form.Item>
                            </Col>
                            <Col span={10}>
                              <Form.Item
                                name={[field.name, "price"]}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter price.",
                                  },
                                ]}
                              >
                                <Input placeholder="Price" />
                              </Form.Item>
                            </Col>
                            <Col span={2}>
                              <CloseOutlined
                                onClick={() => {
                                  remove(field.name);
                                }}
                              />
                            </Col>
                          </Row>
                        </div>
                      ))}
                      <Button type="dashed" onClick={() => add()} block>
                        + Add Sub Item
                      </Button>
                    </div>
                  )}
                </Form.List>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <button
              id="category"
              className=" w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
              type="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddMedicalTestList;
