import React, { useState } from "react";
import axios from "axios";
import { Form, Input, message, Row, Col } from "antd";
import LoadingScreen from "../lib/constants/loadingScreen";

const AddDoctorMedicineAdmin = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  const addMedicine = async (values) => {
    setActionLoading(true);

    const data = {
      name: values.name,
      type: values.type,
    };

    try {
      const response = await axios.post(
        `${apiBaseUrl}/admin/medicine/create`,
        data,
        config
      );

      if (response.data.success) {
        message.success(response.data.msg, 0.6, function onClose() {
          window.location.replace("/dashboard/view/medicine");
        });
      } else {
        const errorMessage =
          response.data.error || "Failed to add medicine! Please try again.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to add medicine:", error);
      const errorMessage =
        error?.response?.data?.error ||
        "Failed to add medicine! Please try again.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={addMedicine}
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
        >
          <div className="text-gray-600 font-medium text-3xl mb-4">
            Add Medicine
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Medicine Name"
                name="name"
                rules={[
                  { required: true, message: "Please input medicine name!" },
                ]}
              >
                <Input placeholder="Medicine name" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Medicine Type"
                name="type"
                rules={[
                  { required: true, message: "Please input medicine type!" },
                ]}
              >
                <Input placeholder="Medicine type (e.g., Tablets/Capsules...)" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <button
              id="addStaffBtn"
              className=" w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
              type="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddDoctorMedicineAdmin;
