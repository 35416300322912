import { useState } from "react";
import axios from "axios";
import { Form, Input, message, Typography } from "antd";
import LoadingScreen from "../lib/constants/loadingScreen";

const { Title } = Typography;

const AddSymptoms = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [imageURL, setImageURL] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    form.setFieldsValue({ photo: file });
    setImageURL(URL.createObjectURL(file));
  };

  const addSymptom = async (values) => {
    setActionLoading(true);
    console.log(values);
    const data = new FormData();
    data.append("name", values.symptomName);
    data.append("image", values.photo);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/add-symptom`,
        data,
        config
      );
      if (response.data.success) {
        message.success("Symptom added.", 0.6, function onClose() {
          window.location.replace("/dashboard/symptoms");
        });
      } else {
        const errorMessage =
          response.data?.error || "Failed to add symptom! Please try again.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to add symptom:", error);
      const errorMessage =
        error.response?.data?.error ||
        "Internal Server Error! Please try again.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <Form
        form={form}
        onFinish={addSymptom}
        layout="vertical"
        className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Add Symptom</Title>
        <Form.Item
          label="Symptom Name"
          name="symptomName"
          rules={[{ required: true, message: "Please enter symptom." }]}
        >
          <Input placeholder="Enter symptom" className="py-2" />
        </Form.Item>

        <Form.Item
          label="Image"
          name="photo"
          rules={[{ required: true, message: "Please upload an image" }]}
        >
          <input
            onChange={handlePictureChange}
            type="file"
            className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
          />
          {imageURL && (
            <div>
              <img
                src={imageURL}
                alt="Department Preview"
                className="mt-4 rounded-lg max-h-40"
              />
            </div>
          )}
        </Form.Item>

        <button
          className="mt-4 w-full bg-blue-600 hover:bg-blue-700 text-white border shadow py-3 px-6 font-semibold text-md rounded"
          type="submit"
        >
          Submit
        </button>
      </Form>
    </>
  );
};
export default AddSymptoms;
