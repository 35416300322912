import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, message, Typography } from "antd";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { useParams } from "react-router-dom";

const { Title } = Typography;

const UpdateAdBanner = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { bannerId } = useParams();
  const [details, setDetails] = useState([]);
  const [form] = Form.useForm();
  const [banner1URL, setBanner1URL] = useState(null);
  const [banner2URL, setBanner2URL] = useState(null);
  const [banner3URL, setBanner3URL] = useState(null);
  const [banner4URL, setBanner4URL] = useState(null);
  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchBanner();
  }, []);

  const fetchBanner = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/ad-banner/${bannerId}`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setBanner1URL(response.data.data.bannerImage1);
          setBanner2URL(response.data.data.bannerImage2);
          setBanner3URL(response.data.data.bannerImage3);
          setBanner4URL(response.data.data.bannerImage4);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching banner details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching ad-banner", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching ad-banner details.";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleBanner1Change = (e) => {
    const file = e.target.files[0];
    setBanner1URL(URL.createObjectURL(file));
    form.setFieldsValue({ bannerImage1: file });
  };

  const handleBanner2Change = (e) => {
    const file = e.target.files[0];
    setBanner2URL(URL.createObjectURL(file));
    form.setFieldsValue({ bannerImage2: file });
  };

  const handleBanner3Change = (e) => {
    const file = e.target.files[0];
    setBanner3URL(URL.createObjectURL(file));
    form.setFieldsValue({ bannerImage3: file });
  };

  const handleBanner4Change = (e) => {
    const file = e.target.files[0];
    setBanner4URL(URL.createObjectURL(file));
    form.setFieldsValue({ bannerImage4: file });
  };

  const updateAdBanner = async (values) => {
    setActionLoading(true);
    try {
      const data = new FormData();
      data.append("bannerImage1", values.bannerImage1);
      data.append("bannerImage2", values.bannerImage2);
      data.append("bannerImage3", values.bannerImage3);
      data.append("bannerImage4", values.bannerImage4);

      const response = await axios.patch(
        `${apiBaseUrl}/ad-banner/${bannerId}`,
        data,
        config
      );

      if (response.data.success) {
        message.success(
          "Ad-banner updated successfully",
          0.6,
          function onClose() {
            window.location.replace("/dashboard/view/ad-banner");
          }
        );
      } else {
        const errorMessage =
          response.data.error || "An error occurred while updating banners.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to update the banners:", error);
      const errorMessage =
        error.response?.data?.error ||
        error.message ||
        "An unexpected error occurred while updating the banners.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {actionLoading || loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Form
            form={form}
            onFinish={updateAdBanner}
            layout="vertical"
            className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-2"
          >
            <Title level={2}>Update Ad-Banner</Title>

            <Form.Item
              label="Banner1"
              name="bannerImage1"
              rules={[{ required: false, message: "Please upload an image" }]}
            >
              <input
                onChange={handleBanner1Change}
                type="file"
                className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
              />
              {banner1URL && (
                <div>
                  <img
                    src={banner1URL}
                    alt="Banner Preview"
                    className="mt-4 rounded-lg max-h-40"
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item
              label="Banner2"
              name="bannerImage2"
              rules={[{ required: false, message: "Please upload an image" }]}
            >
              <input
                onChange={handleBanner2Change}
                type="file"
                className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
              />
              {banner2URL && (
                <div>
                  <img
                    src={banner2URL}
                    alt="Banner Preview"
                    className="mt-4 rounded-lg max-h-40"
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item
              label="Banner3"
              name="bannerImage3"
              rules={[{ required: false, message: "Please upload an image" }]}
            >
              <input
                onChange={handleBanner3Change}
                type="file"
                className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
              />
              {banner3URL && (
                <div>
                  <img
                    src={banner3URL}
                    alt="Banner Preview"
                    className="mt-4 rounded-lg max-h-40"
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item
              label="Banner4"
              name="bannerImage4"
              rules={[{ required: false, message: "Please upload an image" }]}
            >
              <input
                onChange={handleBanner4Change}
                type="file"
                className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
              />
              {banner4URL && (
                <div>
                  <img
                    src={banner4URL}
                    alt="Banner Preview"
                    className="mt-4 rounded-lg max-h-40"
                  />
                </div>
              )}
            </Form.Item>

            <Form.Item>
              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
                type="submit" //htmlType
              >
                Submit
              </button>
            </Form.Item>
          </Form>
        </>
      )}
    </div>
  );
};

export default UpdateAdBanner;
