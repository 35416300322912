import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Checkbox, message, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import LoadingScreen from "../lib/constants/loadingScreen";

const { Title } = Typography;

const CreateDepartment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const [imageURL, setImageURL] = useState(null);
  const [symptoms, setSymptoms] = useState([]);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const openModal = () => setShowModal(true);
  const closeModal = () => setShowModal(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/symptom/`)
      .then((response) => {
        setSymptoms(response.data.data);
      })
      .catch((e) => {
        console.error("Failed to get symptoms", e);
        message.error("Failed to get symptoms. Please try again later");
      });
  }, []);

  const handlePictureChange = (e) => {
    const file = e.target.files[0];
    form.setFieldsValue({ photo: file });
    setImageURL(URL.createObjectURL(file));
  };

  const addDepartment = async (values) => {
    setActionLoading(true);
    const data = new FormData();
    data.append("department", values.department);
    data.append("description", values.description);
    data.append("photo", values.photo);
    data.append("symptom", selectedSymptoms);
    try {
      const response = await axios.post(
        `${apiBaseUrl}/department/create/`,
        data,
        config
      );
      if (response.data.success) {
        message.success(
          "Department created successfully.",
          0.6,
          function onClose() {
            window.location.replace("/dashboard/department");
          }
        );
      } else {
        const errorMessage =
          response.data.error ||
          "An error occurred while creating the department.";
        message.error(errorMessage);
      }
    } catch (err) {
      console.error("Failed to create department:", err);
      const errorMessage =
        err.response?.data?.error ||
        err.message ||
        "An unexpected error occurred while creating the department.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };
  const handleSymptomSelect = (symptomId) => {
    if (selectedSymptoms.includes(symptomId)) {
      setSelectedSymptoms(selectedSymptoms.filter((id) => id !== symptomId));
    } else {
      setSelectedSymptoms([...selectedSymptoms, symptomId]);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <Form
        form={form}
        onFinish={addDepartment}
        layout="vertical"
        className="forbox bg-white w-full max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
      >
        <Title level={2}>Add Department</Title>

        <Form.Item
          label="Department Name"
          name="department"
          rules={[{ required: true, message: "Please enter department" }]}
        >
          <Input placeholder="Enter department" className="py-2" />
        </Form.Item>

        <Form.Item
          label="Department Description"
          name="description"
          rules={[
            { required: true, message: "Please enter department description" },
          ]}
        >
          <Input placeholder="Enter department description" className="py-2" />
        </Form.Item>

        <div>
          <Form.Item
            label="Select Symptoms"
            rules={[
              {
                required: false,
                message: "Please select symptom",
              },
            ]}
          >
            <div>
              {/* Modal toggle */}
              <button
                data-modal-target="symptom-modal"
                data-modal-toggle="symptom-modal"
                className=" text-white bg-gray-600 hover:bg-gray-700 rounded-md px-3 py-1 text-center"
                type="button"
                onClick={openModal}
              >
                Open Symptoms List
              </button>
            </div>
          </Form.Item>

          {showModal && (
            // Main modal
            <div
              id="symptom-modal"
              tabIndex="-1"
              aria-hidden="true"
              className="fixed inset-0 z-50 overflow-y-auto overflow-x-hidden flex justify-center items-center bg-black bg-opacity-50"
            >
              <div className="relative p-4 w-full max-w-2xl max-h-full">
                <div className="relative bg-white rounded-lg shadow">
                  {/* Modal header */}
                  <div className="flex items-center justify-between p-4 border-b rounded-t">
                    <h3 className="text-xl font-semibold text-gray-900">
                      Select Symptoms
                    </h3>
                    <button
                      type="button"
                      className="text-white bg-red-700 hover:bg-red-800 hover:text-white rounded-md w-8 h-8 inline-flex justify-center items-center"
                      data-modal-hide="symptom-modal"
                      onClick={closeModal}
                    >
                      <svg
                        className="w-3 h-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 14 14"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                        />
                      </svg>
                      <span className="sr-only">Close modal</span>
                    </button>
                  </div>
                  {/* Modal body */}
                  <div className="p-4 space-y-4">
                    {/* Symptom selection */}
                    <Input
                      placeholder="Search Symptoms..."
                      className="py-2 px-2"
                      prefix={<SearchOutlined />}
                      allowClear
                      value={searchTerm}
                      onChange={handleSearchChange}
                    />
                    <div className="overflow-y-auto max-h-60">
                      <ul>
                        {symptoms
                          .filter((symptom) =>
                            symptom.name
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((symptom) => (
                            <li key={symptom._id}>
                              <div className="flex items-center">
                                <Checkbox
                                  checked={selectedSymptoms.includes(
                                    symptom._id
                                  )}
                                  onChange={() =>
                                    handleSymptomSelect(symptom._id)
                                  }
                                >
                                  {symptom.name}
                                </Checkbox>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                  {/* Modal footer */}
                  <div className="flex items-center justify-end p-4 border-t rounded-b">
                    {/* <button
                      type="button"
                      className="text-white bg-red-700 hover:bg-red-800 rounded-md px-3.5 py-1.5 text-center"
                      data-modal-hide="symptom-modal"
                      onClick={closeModal}
                    >
                      Close
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div>
          <p className="font-regular text-gray-700">
            Selected Symptoms:{"  "}
            {selectedSymptoms.length === 0 && (
              <span className="text-red-500 italic">No symptoms selected</span>
            )}
          </p>
          <div className="grid grid-cols-2 mb-3" id="symptoms-container">
            {selectedSymptoms.map((symptomId, index) => {
              const selectedSymptom = symptoms.find(
                (symptom) => symptom._id === symptomId
              );

              const discIconStyle = {
                width: "0.4rem",
                height: "0.4rem",
                backgroundColor: "black",
                borderRadius: "50%",
                marginRight: "0.5rem",
              };

              return (
                <div key={index} className="symptom-item flex items-center">
                  <span style={discIconStyle}></span>
                  <div>
                    {selectedSymptom
                      ? selectedSymptom.name
                      : "Symptom not found"}
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <Form.Item
          label="Image"
          name="photo"
          rules={[{ required: true, message: "Please upload an image" }]}
        >
          <input
            onChange={handlePictureChange}
            type="file"
            className="border border-gray-300 border py-2 px-4 w-full rounded-lg text-gray-700"
          />
          {imageURL && (
            <div>
              <img
                src={imageURL}
                alt="Department Preview"
                className="mt-4 rounded-lg max-h-40"
              />
            </div>
          )}
        </Form.Item>

        <Form.Item>
          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit" //htmlType
          >
            Submit
          </button>
        </Form.Item>
      </Form>
    </>
  );
};
export default CreateDepartment;
