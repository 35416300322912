import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Input, Select, Spin, Space, message } from "antd";
import debounce from "lodash/debounce";
import { SearchOutlined } from "@ant-design/icons";

const { Option } = Select;

const CheckRefers = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [assignedFilter, setAssignedFilter] = useState("unassigned");
  const [filteredDetails, setFilteredDetails] = useState([]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/appointments/referred`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error("Failed to get appointment details:", e);
        message.error("Failed to get appointment details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    filterDetails();
  }, [searchTerm, assignedFilter, details]);

  const handleAssignedFilterChange = (value) => {
    setAssignedFilter(value);
  };

  const handleSearchTermChange = (value) => {
    setSearchTerm(value);
  };

  const filterDetails = debounce(() => {
    let filteredData = details.filter((detail) => {
      if (assignedFilter === "all") {
        return true;
      } else if (assignedFilter === "assigned") {
        return detail.refer.toDoctor;
      } else {
        return !detail.refer.toDoctor;
      }
    });

    filteredData = filteredData.filter(
      (detail) =>
        (detail.patientId?.userID &&
          detail.patientId.userID
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        detail.fullname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        (detail.appointmentNumber &&
          detail.appointmentNumber
            .toLowerCase()
            .includes(searchTerm.toLowerCase())) ||
        (detail.mobile &&
          detail.mobile.toLowerCase().includes(searchTerm.toLowerCase()))
    );

    setFilteredDetails(filteredData);
  }, 300);

  const columns = [
    {
      title: "Appointment No.",
      dataIndex: "appointmentNumber",
      key: "appointmentNumber",
    },
    {
      title: "UserID",
      dataIndex: ["patientId", "userID"],
      key: "userID",
    },
    {
      title: "Patient Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Phone",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Referred by(Dr)",
      dataIndex: ["refer", "by", "fullname"],
      key: "byDoctor",
    },
    {
      title: "From Department",
      dataIndex: ["refer", "by", "department", "department"],
      key: "fromDepartment",
    },
    {
      title: "Referred To(Dr)",
      key: "department",
      render: (_, record) => {
        const { refer } = record;
        if (refer.toDoctor !== null) {
          return refer.toDoctor.fullname;
        } else {
          return <span className="text-red-500">Unassigned</span>;
        }
      },
    },
    {
      title: "To Department",
      dataIndex: ["refer", "toDepartment", "department"],
      key: "department",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) =>
        record.refer.toDoctor === null ? (
          <Space size="middle">
            <Link
              to={"/dashboard/refer/update/" + record._id}
              className="text-indigo-600 hover:text-indigo-900"
            >
              <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                Assign
              </button>
            </Link>
          </Space>
        ) : (
          <>-</>
        ),
    },
  ];

  return (
    <>
      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Refers</h1>
        </div>
        <div className="flex justify-start items-center mb-4">
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            style={{ width: 300 }}
            value={searchTerm}
            allowClear
            onChange={(e) => handleSearchTermChange(e.target.value)}
          />
          <Select
            value={assignedFilter}
            onChange={handleAssignedFilterChange}
            style={{ width: 200, marginLeft: 8 }}
          >
            <Option value="unassigned">Unassigned</Option>
            <Option value="assigned">Assigned</Option>
            <Option value="all">All</Option>
          </Select>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default CheckRefers;
