import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { Table, Image, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import LoadingScreen from "../lib/constants/loadingScreen";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import { message } from "antd";

const ViewSymptoms = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/symptom/`)
      .then((response) => {
        setDetails(response.data.data);
        setFilteredDetails(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching symptoms:", error);
        message.error("Failed to get symptoms.");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const deleteSymptom = async (symptomId) => {
    setActionLoading(true);
    try {
      if (window.confirm("Are you sure want to delete this symptom?")) {
        const response = await axios.delete(
          `${apiBaseUrl}/symptom/delete/${symptomId}`,
          config
        );
        if (response.data.success) {
          message.success("Symptom Deleted", 0.6, function onClose() {
            fetchData();
          });
        } else {
          message.error("Failed to delete");
        }
      }
    } catch (e) {
      console.error("Failed to delete:", e);
      message.error("Failed to delete. Please try again.");
    } finally {
      setActionLoading(false);
    }
  };

  const filteredSymptoms = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter((symptom) =>
          symptom?.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchData();
      }
    }, 500),
    [searchTerm, details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filteredSymptoms(searchTerm);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      key: "image",
      render: (image) => (
        <Image
          src={image}
          width={80}
          height={80}
          className="object-contain rounded-md"
        />
      ),
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/symptom/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              deleteSymptom(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Symptoms</h1>
          <div className="flex justify-between mt-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
            <Link to="/dashboard/add_symptom">
              <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                Add Symptom
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            loading={loading}
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ViewSymptoms;
