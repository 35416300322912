import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import { useParams, useLocation } from "react-router-dom";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { message, Row, Col, Typography } from "antd";

const { Title } = Typography;

const ChangeReferredDepartmentByStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();

  let location = useLocation();
  const {
    full_name,
    problem,
    doctorCheckIfInReferred,
    departmentCheckIfInReferred,
    departmentIsSelectedCheckIfInReferred,
  } = location.state;

  const [actionLoading, setActionLoading] = useState(false);
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentToSelectedId, setDepartmentToSelectedId] = useState("");
  const [departmentToStoringValue, setDepartmentToStoringValue] = useState("");
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentToInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentToStoringValue(selectedDepartment);
      setDepartmentToSelectedId(selectedDepartment.value);
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const referDepartment = async (e) => {
    e.preventDefault();

    const data = {
      assigningDepartment: departmentToSelectedId,
    };

    try {
      if (window.confirm("Are you sure want to refer this appointment?")) {
        setActionLoading(true);
        const response = await axios.post(
          `${apiBaseUrl}/staff/appointment/referDepartment/${appointmentId}`,
          data,
          config
        );
        if (response.data.msg == "Department assigned") {
          message.success("Patient refered success", 0.6, function onClose() {
            window.location.replace("/dashboard/view/referred/appointment");
          });
        } else if (departmentToSelectedId == "") {
          message.error(
            "Same department Selected! Please change department or back to referred page."
          );
        } else {
          message.error("Failed to refer department! Please try again.");
        }
      }
    } catch (err) {
      console.error("Failed to refer department: ", err);
      message.error("Failed to refer department! Please try again.");
    } finally {
      setActionLoading(false);
    }
  };
  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={referDepartment}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Change Refer-Department</Title>
          </div>
          <p className="text-base -mb-3 -mt-1">
            Patient Name:{" "}
            <span className="text-gray-700 italic">{full_name}</span>
          </p>
          <p className="text-base">
            Problem: <span className="text-gray-700 italic">{problem}</span>
          </p>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Department(FROM)</label>
              <input
                id="from_doctor"
                type="text"
                value={departmentCheckIfInReferred}
                disabled
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-700"
                name="title"
                required
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Doctor(FROM)</label>
              <input
                id="from_doctor"
                type="text"
                value={doctorCheckIfInReferred}
                disabled
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-700"
                name="title"
                required
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Department (REFER TO)</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={
                  departmentToStoringValue
                    ? departmentToStoringValue
                    : departmentIsSelectedCheckIfInReferred
                }
                onChange={handleDepartmentToInputChange}
                isSearchable
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <button
            id="referDepartmentButton"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </>
  );
};
export default ChangeReferredDepartmentByStaff;
