import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, message, Row, Col } from "antd";
import { useParams } from "react-router-dom";
import LoadingScreen from "../../../lib/constants/loadingScreen";

const UpdateMedicalCategory = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { categoryId } = useParams();
  const [form] = Form.useForm();
  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/medical_test/category/${categoryId}`, config)
      .then((response) => {
        if (response.data.success) {
          form.setFieldsValue({
            category: response.data.data.category,
          });
        } else {
          const errorMessage =
            response.data.error ||
            "Failed to fetch category details! Please try again";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching category details", error);
        const errorMessage =
          error.response.data.error ||
          "Failed to fetch category details! Please try again";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateMedicalCategory = async (values) => {
    setActionLoading(true);

    const data = {
      category: values.category,
    };

    try {
      const response = await axios.put(
        `${apiBaseUrl}/medical_test/category/${categoryId}`,
        data,
        config
      );

      if (response.data.success) {
        message.success(
          "Test category updated successfully",
          0.6,
          function onClose() {
            window.location.replace("/dashboard/viewmedicaltestcategory");
          }
        );
      } else {
        const errorMessage =
          response.data.error || "Failed to update category! Please try again.";
        message.error(errorMessage);
      }
    } catch (error) {
      console.error("Failed to update category:", error);
      const errorMessage =
        error?.response?.data?.error ||
        "Failed to update category! Please try again.";
      message.error(errorMessage);
    } finally {
      setActionLoading(false);
    }
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <Form
          form={form}
          layout="vertical"
          onFinish={updateMedicalCategory}
          className="forbox w-full bg-white max-w-2xl h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg"
        >
          <div className="text-gray-800 font-medium text-3xl mb-4">
            Update Category
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Form.Item
                label="Category Name"
                name="category"
                rules={[
                  { required: true, message: "Please input category name!" },
                ]}
              >
                <Input placeholder="Category name" />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <button
              id="category"
              className=" w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
              type="submit"
            >
              Submit
            </button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default UpdateMedicalCategory;
