import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import LoadingScreen from "../lib/constants/loadingScreen";
import { message, Row, Col, Typography } from "antd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";
import { calculateAge } from "../lib/constants/calculateAge";
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import "./placeholder.css";

const { Title } = Typography;

const AddNewAppointmentStaff = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [actionLoading, setActionLoading] = useState(false);
  const [patientDetails, setPatientDetails] = useState([]);
  const [problem, setProblem] = useState("");
  /// current Medication and past disease
  const [currentMedication, setCurrentMedication] = useState("");
  const [pastDiseases, setPastDiseases] = useState("");

  const [address, setAddress] = useState("");
  const [DOB, setDOBinAD] = useState("");
  const [inputAge, setInputAge] = useState("");
  const [patientSelectedId, setPatientSelectedId] = useState(null);
  const [patientSelectedName, setPatientSelectedName] = useState("");
  const [patientStoringValue, setPatientStoringValue] = useState("");
  const [patientSelectedContact, setPatientSelectedContact] = useState("");
  // const [patientSelectedAge, setPatientSelectedAge] = useState("");
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState("");
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState("");
  // const [dateSelectedDetails, setSelectedDateDetails] = useState([]);
  // const [dateSelectedId, setDateSelectedId] = useState("");
  // const [dateStoringValue, setDateStoringValue] = useState("");
  // const [timeSelectedDetails, setSelectedTimeDetails] = useState([]);
  // const [timeSelectedId, setTimeSelectedId] = useState("");
  // const [timeStoringValue, setTimeStoringValue] = useState("");
  const [time, setTime] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  // age calculate
  const [isDOBMatched, setIsDOBMatched] = useState(false);
  const [dateBS, setDateBS] = useState("");
  const [dateAD, setDateAD] = useState("");
  const [defaultDateBS, setDefaultDateBS] = useState("");

  let calculatedAge;

  useEffect(() => {
    calculatedAge = calculateAge(DOB);

    if (
      calculatedAge !== undefined &&
      calculatedAge !== "Invalid date of birth"
    ) {
      setInputAge(calculatedAge);

      if (dateAD === DOB) {
        setIsDOBMatched(true);
      } else {
        setIsDOBMatched(false);
      }
    } else {
      setInputAge(null);
    }
  }, [DOB, dateAD]);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewpatientsstaff`, config)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((patient) => ({
            value: patient._id,
            label: patient.fullname,
            // age: patient.age,
            contact: patient.phone,
            DOB: patient.DOB,
            DOBinBS: patient.DOBinBS,
            address: patient.address,
          }));
          setPatientDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to get user details:", e);
        message.error("Failed to get user details! Please try again.");
      });
  }, []);

  // console.log(patientStoringValue);

  // handle changes for patient dropdown..
  const handlePatientInputChange = (selectedPatient) => {
    if (selectedPatient) {
      setPatientStoringValue(selectedPatient);
      setPatientSelectedId(selectedPatient.value);
      // setInputAge(selectedPatient.age);
      setPatientSelectedContact(selectedPatient.contact);
      setPatientSelectedName(selectedPatient.label);
      setAddress(selectedPatient.address);
      const convertedDOB = selectedPatient.DOB?.split("T")[0];
      const convertedDOBinBS = selectedPatient.DOBinBS?.split("T")[0];

      setDefaultDateBS(convertedDOBinBS);
      setDOBinAD(convertedDOB);
    }
  };

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
      // setDateStoringValue("");
      // setTimeStoringValue("");
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
      // setDateStoringValue("");
      // setTimeStoringValue("");
    }
  };

  // fetch dateTime from appointmet date time
  // useEffect(() => {
  //   if (doctorSelectedId) {
  //     axios
  //       .get(`${apiBaseUrl}/staff/appointment/dateAndtime/${doctorSelectedId}`)
  //       .then((response) => {
  //         if (response.data.success) {
  //           const options = response.data.data.map((date) => ({
  //             value: date.date,
  //             label: date.date,
  //             time: date.time,
  //           }));
  //           setSelectedDateDetails(options);
  //         }
  //       });
  //   }
  // }, [doctorSelectedId]);

  // const handleDateInputChange = (selectedDate) => {
  //   if (selectedDate) {
  //     setDateStoringValue(selectedDate);
  //     setDateSelectedId(selectedDate.value);
  //     // setSelectedTimeDetails(selectedDate.time);
  //     // setTimeStoringValue("");
  //   }
  // };

  // handle time for selected date:
  // const timeDataOptions = timeSelectedDetails.map((time) => ({
  //   value: time,
  //   label: time,
  // }));

  // const handleTimeInputChange = (selectedTime) => {
  //   if (selectedTime) {
  //     setTimeStoringValue(selectedTime);
  //     setTimeSelectedId(selectedTime.value);
  //   }
  // };

  const createAppointment = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      patientId: patientSelectedId,
      fullname: patientSelectedName,
      mobile: patientSelectedContact,
      address,
      // age: inputAge,
      DOB,
      DOBinBS: dateBS,
      problem,
      department: departmentSelectedId,
      doctorId: doctorSelectedId,
      date: appointmentDate,
      time,
      currentMedication,
      pastDiseases,
    };

    axios
      .post(`${apiBaseUrl}/staff/patient/bookDoctorAppointment`, data, config)
      .then((response) => {
        if (response.data.success) {
          message.success(
            "Appointment Created Successfully.",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/viewappointment");
            }
          );
        } else {
          message.error("Something went wrong! Please try again.");
        }
      })
      .catch((err) => {
        console.error("Error booking appointment:", err);
        message.error("Error booking appointment! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    setTime(formattedTime);
  };

  const dateBStoAD = () => {
    if (dateAD) {
      setDOBinAD(dateAD);
    } else {
      setInputAge(null);
    }
  };

  const handleDateInBS = ({ bsDate, adDate }) => {
    setDateBS(bsDate);
    setDateAD(adDate);
  };

  return (
    <div>
      {actionLoading && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-2xl bg-white bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={createAppointment}
        >
          <div className="-my-3 text-start">
            <Title level={2}>Create appointment</Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <div>
                <label className="text-base">Patient Name</label>
                <Select
                  options={patientDetails}
                  placeholder="Select patient"
                  onChange={handlePatientInputChange}
                  value={patientStoringValue}
                  isSearchable
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      borderRadius: "0.375rem",
                    }),
                  }}
                />
              </div>
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div>
                <label className="text-base">Contact</label>
                <input
                  value={patientSelectedContact}
                  onChange={(e) => {
                    setPatientSelectedContact(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Contact"
                  required
                />
              </div>
            </Col>
            <Col span={12}>
              <div>
                <label className="text-base">DOB (in BS)</label>
                <Calendar
                  key={defaultDateBS}
                  onChange={handleDateInBS}
                  theme="blue"
                  defaultDate={defaultDateBS}
                  hideDefaultValue={defaultDateBS ? false : true}
                  language="en"
                  placeholder="Select Date(BS)"
                  className="border border-gray-300 border py-1.5 px-3 rounded-md text-gray-800 custom-placeholder w-full"
                />
              </div>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <div>
                <label className="text-base">Address</label>
                <input
                  value={address}
                  onChange={(e) => {
                    setAddress(e.target.value);
                  }}
                  className="border border-solid border-gray-300 focus:border-red-400 py-2 px-2 w-full rounded-md"
                  name="title"
                  placeholder="Address"
                  required
                />
              </div>
            </Col>
            <Col span={6}>
              <div>
                <label className="text-base ms-1">DOB (in AD)</label>
                <input
                  id="DOB"
                  value={DOB}
                  type="date"
                  onChange={(e) => {
                    setDOBinAD(e.target.value);
                  }}
                  max={new Date().toISOString().split("T")[0]}
                  className="border-solid border-gray-300 border h-10 py-2 px-2 w-full rounded-md text-gray-700 date-picker"
                  name="dob"
                  placeholder="Select Date"
                />
              </div>
            </Col>
            <Col span={6}>
              <div>
                <label className="text-base ms-1">Age</label>
                <input
                  onChange={(e) => {
                    setInputAge(e.target.value);
                  }}
                  value={inputAge}
                  className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                  name="age"
                  type="text"
                  placeholder="Age"
                  readOnly
                  required
                />
              </div>
            </Col>
          </Row>
          {!isDOBMatched && (
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <div className="-mt-3 -mb-4 flex justify-end">
                  <p className="text-red-500 italic me-2">
                    The selected DOB(in BS) does not match the DOB(in AD).
                  </p>
                  <Link
                    onClick={dateBStoAD}
                    className="text-blue-500 italic border-b hover:text-blue-600 font-semibold inline-flex items-center"
                  >
                    Click here to correct it.
                  </Link>
                </div>
              </Col>
            </Row>
          )}
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Problem</label>
              <input
                onChange={(e) => {
                  setProblem(e.target.value);
                }}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                name="title"
                placeholder="Problem"
                value={problem}
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Current Medication</label>
              <input
                onChange={(e) => {
                  setCurrentMedication(e.target.value);
                }}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                name="title"
                placeholder="CurrentMedication"
                value={currentMedication}
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Past Diseases</label>
              <input
                onChange={(e) => {
                  setPastDiseases(e.target.value);
                }}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md"
                name="title"
                placeholder="Past Diseases"
                value={pastDiseases}
                required
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={departmentStoringValue}
                onChange={handleDepartmentInputChange}
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={doctorStoringValue}
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Date</label>
              {/* <Select
                options={dateSelectedDetails}
                value={dateStoringValue}
                onChange={handleDateInputChange}
                placeholder="Select date"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              /> */}
              <input
                id="appointmentDate"
                type="date"
                onChange={(e) => {
                  setAppointmentDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                name="appointmentDate"
                placeholder="Select Date"
              />
            </Col>
            <Col span={12}>
              {/* <label className="text-base ms-1">Time</label>
              <Select
                options={timeDataOptions}
                value={timeStoringValue}
                onChange={handleTimeInputChange}
                placeholder="Select time"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              /> */}

              <div className="w-full mr-4">
                <label className="text-base" htmlFor={`appointment-time`}>
                  Time
                </label>
                <Flatpickr
                  data-enable-time
                  data-disable-date
                  placeholder="Pick a time"
                  onChange={(selectedDates, dateStr, flatpickrInstance) =>
                    handleTimeChange(selectedDates, dateStr, flatpickrInstance)
                  }
                  className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md transition-colors duration-200"
                  options={{
                    enableTime: true,
                    noCalendar: true,
                    dateFormat: "h:i K",
                    time_24hr: false,
                    allowInput: true,
                    clearIcon: true,
                  }}
                />
              </div>
            </Col>
          </Row>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default AddNewAppointmentStaff;
