import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { Table, Input, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";
import { MdDelete, MdEditSquare } from "react-icons/md";
import { message } from "antd";
import LoadingScreen from "../../../lib/constants/loadingScreen";

const ViewMedicalTestCategory = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [filteredDetails, setFilteredDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/medical_test/categories`, config)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
          setFilteredDetails(response.data.data);
        }
      })
      .catch((error) => {
        console.error("Error fetching medical category data:", error);
        message.error(
          "Failed to fetch medical category data. Please try again later."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteCategory = async (categoryId) => {
    setActionLoading(true);
    try {
      if (window.confirm("Are you sure want to delete this category?")) {
        const response = await axios.delete(
          `${apiBaseUrl}/medical_test/category/${categoryId}`,
          config
        );
        if (response.data.success) {
          message.success("Medical Category Deleted", 0.6, function onClose() {
            fetchData();
          });
        } else {
          message.error("Failed to delete medical category.");
        }
      }
    } catch (error) {
      console.error("Error deleting medical category", error);
      message.error(
        "Failed to delete medical category! Please try again later."
      );
    } finally {
      setActionLoading(false);
    }
  };

  const filteredMedicines = useCallback(
    debounce((searchTerm) => {
      if (searchTerm.trim() !== "") {
        const filtered = details.filter((category) =>
          category?.category.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredDetails(filtered);
      } else {
        fetchData();
      }
    }, 500),
    [searchTerm, details]
  );

  const handleSearch = (e) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    filteredMedicines(searchTerm);
  };

  const columns = [
    {
      title: "SN",
      dataIndex: "sn",
      key: "sn",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Category Name",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Action",
      key: "action",
      width: 300,
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/medical_category/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              deleteCategory(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && <LoadingScreen />}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">
            Test Categories
          </h1>
          <div className="flex justify-between mt-3">
            <Input
              placeholder="Search..."
              prefix={<SearchOutlined />}
              allowClear
              value={searchTerm}
              onChange={handleSearch}
              style={{ width: 300 }}
            />
            <div>
              <Link to="/dashboard/add/medical_category">
                <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                  Add Category
                </button>
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={filteredDetails}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ViewMedicalTestCategory;
