import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { MdOutlineLocalHospital } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { TbReportMoney } from "react-icons/tb";
import { HiOutlineLogout } from "react-icons/hi";
import { Menu } from "antd";
import "./sidebar.css";
import {
  DASHBOARD_SIDEBAR_LINKS,
  DASHBOARD_SIDEBAR_BOTTOM_LINKS,
  DASHBOARD_APPOINTMENT_SIDEBAR_LINKS,
  STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS,
  ADMIN_DASHBOARD_USER_SIDEBAR_LINKS,
  ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS,
} from "../../lib/constants";

const linkClass =
  "flex items-center px-3 py-2 text-base hover:bg-neutral-100 hover:no-underline active:bg-blue-100 rounded-sm";

// Modal component
const Modal = ({ title, message, onClose, onConfirm }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="absolute inset-0 bg-gray-900 opacity-55 cursor-pointer"
        onClick={onClose}
      ></div>
      <div className="bg-white w-96 p-5 rounded-lg shadow-lg z-50">
        <div className="text-lg font-semibold mb-4">{title}</div>
        <div className="mb-4">{message}</div>
        <div className="flex justify-end">
          <button
            className="bg-gray-200 px-4 py-2 rounded-md mr-2"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={onConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default function Sidebar() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMenuAvailable =
    DASHBOARD_APPOINTMENT_SIDEBAR_LINKS &&
    DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.length > 0;
  const isStaffMenuAvailable =
    STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS &&
    STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.length > 0;
  const isAdminUserMenuAvailable =
    ADMIN_DASHBOARD_USER_SIDEBAR_LINKS &&
    ADMIN_DASHBOARD_USER_SIDEBAR_LINKS.length > 0;
  const isAdminTestBillMenuAvailable =
    ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS &&
    ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS.length > 0;
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  const location = useLocation();

  const handleLogout = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    const updateSelectedKeys = () => {
      const activeLink = [
        ...DASHBOARD_SIDEBAR_LINKS,
        ...DASHBOARD_SIDEBAR_BOTTOM_LINKS,
      ].find((link) => link.path === location.pathname);
      if (activeLink) {
        setSelectedKeys([activeLink.key]);
      } else {
        let foundLink = null;
        if (isAdminUserMenuAvailable) {
          foundLink = ADMIN_DASHBOARD_USER_SIDEBAR_LINKS.find(
            (link) => link.path === location.pathname
          );
        }
        if (!foundLink && isAdminTestBillMenuAvailable) {
          foundLink = ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS.find(
            (link) => link.path === location.pathname
          );
        }
        if (!foundLink && isMenuAvailable) {
          foundLink = DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.find(
            (link) => link.path === location.pathname
          );
        }
        if (!foundLink && isStaffMenuAvailable) {
          foundLink = STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.find(
            (link) => link.path === location.pathname
          );
        }
        if (foundLink) {
          setSelectedKeys([foundLink.key]);
        } else {
          setSelectedKeys([]);
        }
      }
    };
    updateSelectedKeys();
  }, [
    location.pathname,
    isMenuAvailable,
    isStaffMenuAvailable,
    isAdminUserMenuAvailable,
    isAdminTestBillMenuAvailable,
  ]);

  const getDefaultOpenKeys = () => {
    const openKeys = [];

    if (isAdminUserMenuAvailable) {
      const adminUserLink = ADMIN_DASHBOARD_USER_SIDEBAR_LINKS.find(
        (link) => link.path === location.pathname
      );
      if (adminUserLink) {
        openKeys.push("admin-users");
      }
    }

    if (isAdminTestBillMenuAvailable) {
      const adminTestBillLink = ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS.find(
        (link) => link.path === location.pathname
      );
      if (adminTestBillLink) {
        openKeys.push("admin-testbilling");
      }
    }

    if (isMenuAvailable) {
      const appointmentLink = DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.find(
        (link) => link.path === location.pathname
      );
      if (appointmentLink) {
        openKeys.push("appointments");
      }
    }

    if (isStaffMenuAvailable) {
      const staffAppointmentLink =
        STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.find(
          (link) => link.path === location.pathname
        );
      if (staffAppointmentLink) {
        openKeys.push("staff-appointments");
      }
    }

    return openKeys;
  };

  const renderSidebarLink = (link) => {
    return (
      <Menu.Item
        key={link.key}
        icon={<span style={{ fontSize: "17px" }}>{link.icon}</span>}
        className={classNames(
          Array.isArray(selectedKeys) && selectedKeys.includes(link.key)
            ? "bg-blue-100 text-blue-800"
            : "text-neutral-800",
          linkClass
        )}
      >
        <Link to={link.path}>{link.label}</Link>
      </Menu.Item>
    );
  };

  return (
    <div
      className={classNames(
        "bg-white w-60 py-3 ps-1 flex flex-col relative sidebar-container",
        { "w-16": collapsed }
      )}
    >
      <Link to={"/dashboard"}>
        <div className="flex items-center gap-2 ms-2 mb-2 px-1 py-3">
          <MdOutlineLocalHospital fontSize={24} color="black" />
          {!collapsed && (
            <span className="text-gray-700 text-xl font-medium">
              Tulsi Multi Clinic
            </span>
          )}
        </div>
      </Link>

      <Menu
        mode="inline"
        selectedKeys={selectedKeys}
        onSelect={(keys) => setSelectedKeys(keys)}
        defaultOpenKeys={getDefaultOpenKeys()}
        inlineCollapsed={collapsed}
        className="scrollable-menu"
      >
        {DASHBOARD_SIDEBAR_LINKS.map(renderSidebarLink)}

        {isAdminUserMenuAvailable && (
          <Menu.SubMenu
            key="admin-users"
            title={
              <div className="flex items-center gap-2">
                <FaUsers fontSize={21} color="black" />
                {!collapsed && (
                  <span className="text-neutral-800 text-base font-normal">
                    Users
                  </span>
                )}
              </div>
            }
          >
            {ADMIN_DASHBOARD_USER_SIDEBAR_LINKS.map(renderSidebarLink)}
          </Menu.SubMenu>
        )}

        {isAdminTestBillMenuAvailable && (
          <Menu.SubMenu
            key="admin-testbilling"
            title={
              <div className="flex items-center gap-2">
                <TbReportMoney fontSize={21} color="black" />
                {!collapsed && (
                  <span className="text-neutral-800 text-base font-normal">
                    TestBilling
                  </span>
                )}
              </div>
            }
          >
            {ADMIN_DASHBOARD_TESTBILLING_SIDEBAR_LINKS.map(renderSidebarLink)}
          </Menu.SubMenu>
        )}

        {isMenuAvailable && (
          <Menu.SubMenu
            key="appointments"
            title={
              <div className="flex items-center gap-2">
                <MdOutlineLocalHospital fontSize={21} color="black" />
                {!collapsed && (
                  <span className="text-neutral-800 text-base font-normal">
                    Appointments
                  </span>
                )}
              </div>
            }
          >
            {DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.map(renderSidebarLink)}
          </Menu.SubMenu>
        )}

        {isStaffMenuAvailable && (
          <Menu.SubMenu
            key="staff-appointments"
            title={
              <div className="flex items-center gap-2">
                <MdOutlineLocalHospital fontSize={21} color="black" />
                {!collapsed && (
                  <span className="text-neutral-800 text-base font-normal">
                    Appointments
                  </span>
                )}
              </div>
            }
          >
            {STAFF_DASHBOARD_APPOINTMENT_SIDEBAR_LINKS.map(renderSidebarLink)}
          </Menu.SubMenu>
        )}

        <Menu.ItemGroup title="Other Links" className="sticky-menu-group">
          {/* {DASHBOARD_SIDEBAR_BOTTOM_LINKS.map(renderSidebarLink)} */}
          <Menu.Item
            key="logout"
            icon={<HiOutlineLogout />}
            onClick={handleLogout}
            className={classNames(linkClass)}
            style={{ color: "red" }}
          >
            Logout
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
      {isModalOpen && (
        <Modal
          title="Logout"
          message="Are you sure you want to log out?"
          onClose={() => setIsModalOpen(false)}
          onConfirm={() => {
            localStorage.clear();
            navigate("/", { replace: true });
          }}
        />
      )}
    </div>
  );
}
