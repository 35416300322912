import React, { useEffect, useState } from "react";
import apiService from "../components/billing/apiService";
import BillTable from "../components/billing/BillTable";

const BillListPage = () => {
  const [bills, setBills] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchBills();
  }, []);

  const fetchBills = async () => {
    try {
      const response = await apiService.fetchBills();
      setBills(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching bills:", error);
      setLoading(false);
    }
  };

  return (
    <div>
      <h1 className="text-3xl font-bold decoration-gray-400">
        Medicine Billing Archive
      </h1>
      <BillTable data={{ bills, loading }} />
    </div>
  );
};

export default BillListPage;
