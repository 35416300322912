import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import LoadingScreen from "../../../lib/constants/loadingScreen";
import { appointmentContext } from "../../../pages/ViewFollowUpAppointments";
import { message } from "antd";

const ActionFollowUpAppointment = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const {
    appointmentId,
    appointmentNumber,
    departmentId,
    full_name,
    problem,
    report,
  } = useContext(appointmentContext);

  const [appointment_status, setAppointmentStatus] = useState("Ended");
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization:
        "Bearer " +
        (localStorage.getItem("doctorticket") ||
          localStorage.getItem("staffticket")),
    },
  };

  const updateAppointmentStatus = () => {
    if (window.confirm("Are you sure want to end this appointment?")) {
      setActionLoading(true);
      const data = {
        appointmentStatus: appointment_status,
      };
      axios
        .put(
          `${apiBaseUrl}/doctor/updateAppointmentStatus/` + appointmentId,
          data,
          config
        )
        .then((response) => {
          if (response.data.msg == "Updated Status") {
            message.success("Appointment ended", 0.6, function onClose() {
              window.location.replace("/dashboard/view/followup/appointments");
            });
          } else {
            message.error("Failed To Update");
          }
        })
        .catch((err) => {
          console.error("Error", err);
          message.error("Failed to update");
        })
        .finally(() => {
          setActionLoading(false);
        });
    }
  };

  return (
    <>
      {actionLoading && <LoadingScreen />}
      <div className="bg-white px-4 pt-6">
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center">
            <h1
              className="text-3xl leading-6 font-medium text-center text-gray-900"
              id="modal-title"
            >
              Actions
            </h1>
            <div className="">
              <p className="text-sm text-gray-500 mx-5">
                <div className="py-2 mx-5">
                  <ul className="my-4 space-y-3 mx-8">
                    <li>
                      <Link
                        to={`/dashboard/viewreport/${appointmentId}`}
                        state={{
                          report,
                          appointmentNumber,
                          full_name,
                        }}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">
                          View Report
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/dashboard/viewOngoingAppointmentbyDoctor/refer_department/${appointmentId}/${departmentId}`}
                        state={{ full_name, problem }}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">
                          Refer Department
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/dashboard/viewOngoingAppointmentbyDoctor/add_prescription/${appointmentId}`}
                        state={{ full_name, problem }}
                        className="flex items-center px-12 py-4 text-center font-bold text-black shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">
                          Add Prescription
                        </span>
                      </Link>
                    </li>
                    <li>
                      <button
                        onClick={updateAppointmentStatus}
                        className="flex items-center px-12 py-4 text-center font-bold text-red-600 shadow rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow"
                      >
                        <span className="flex-1 whitespace-nowrap">
                          End Appointment
                        </span>
                      </button>
                    </li>
                  </ul>
                </div>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ActionFollowUpAppointment;
