import React, { useState, useEffect, useCallback } from "react";
import { Table, Input, Spin } from "antd";
import { SearchOutlined, PlusOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { Button, DatePicker } from "antd";
import debounce from "lodash/debounce";

const { RangePicker } = DatePicker;

const BillTable = ({ data }) => {
  const navigate = useNavigate();
  const { bills, loading } = data;
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [dateRange, setDateRange] = useState([null, null]);

  useEffect(() => {
    if (!loading) {
      setDataSource(bills);
    }
  }, [bills, loading]);

  const filteredData = useCallback(
    debounce((searchText, dateRange) => {
      let filtered = bills;

      const trimmedSearchText = searchText?.toString().trim();

      if (trimmedSearchText !== "") {
        filtered = bills.filter((record) =>
          Object.keys(record).some((key) => {
            const value = record[key];
            if (key === "patient") {
              return Object.values(value).some(
                (subValue) =>
                  subValue &&
                  subValue
                    .toString()
                    .toLowerCase()
                    .includes(trimmedSearchText.toLowerCase())
              );
            } else {
              return (
                value &&
                value
                  .toString()
                  .toLowerCase()
                  .includes(trimmedSearchText.toLowerCase())
              );
            }
          })
        );
      }
      if (dateRange && dateRange[0] && dateRange[1]) {
        const [startDate, endDate] = dateRange.map((date) =>
          date.format("YYYY-MM-DD")
        );
        filtered = filtered.filter((date) => {
          const billingDate = moment
            .utc(date.billDate)
            .tz("Asia/Kathmandu")
            .format("YYYY-MM-DD");
          return billingDate >= startDate && billingDate <= endDate;
        });
      }
      setDataSource(filtered);
    }, 500),
    [bills]
  );

  const generateBill = () => {
    navigate("/dashboard/generate/bill");
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value || "";
    setSearchText(searchTerm);
    filteredData(searchTerm, dateRange);
  };

  const handleDateChange = (dates) => {
    setDateRange(dates || [null, null]);
    filteredData(searchText, dates);
  };

  const handleResetFilters = () => {
    console.log(bills);

    setSearchText("");
    setDateRange([null, null]);
    filteredData(bills); //need to change here after testing.
  };

  const convertToNepaliTime = (utcTime) => {
    return moment.utc(utcTime).tz("Asia/Kathmandu").format("YYYY-MM-DD h:mm A");
  };

  const columns = [
    {
      title: "Bill No",
      dataIndex: "billNo",
      key: "billNo",
    },
    {
      title: "Patient Name",
      dataIndex: ["patient", "fullname"],
      key: "patientName",
    },
    {
      title: "DOB",
      dataIndex: ["patient", "DOB"],
      key: "DOB",
      render: (DOB) => (DOB ? DOB.split("T")[0] : "-"),
    },
    {
      title: "Mobile",
      dataIndex: ["patient", "mobile"],
      key: "mobile",
    },
    {
      title: "Address",
      dataIndex: ["patient", "address"],
      key: "address",
    },
    {
      title: "Net Amount",
      dataIndex: "totalNet",
      key: "totalNet",
    },
    {
      title: "Bill Date",
      dataIndex: "billDate",
      key: "billDate",
      render: (billDate) => {
        const nepaliTime = convertToNepaliTime(billDate);
        return <span>{nepaliTime}</span>;
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/bill/${record._id}`}
            className="bg-gray-500 hover:bg-gray-600 hover:text-white text-white font-bold rounded-lg shadow-md py-2 px-4 me-2"
          >
            Update
          </Link>
          <Link
            to={`/dashboard/pharmacy/print-invoice/${record._id}`}
            className="bg-gray-50 border hover:bg-gray-100 hover:text-black text-black font-bold rounded-lg shadow-md py-2 px-4"
          >
            Print
          </Link>
        </>
      ),
    },
  ];

  return (
    <div className="pt-4">
      <div className="flex justify-between items-center mb-4">
        <div>
          <Input
            placeholder="Search..."
            prefix={<SearchOutlined />}
            allowClear
            value={searchText}
            onChange={handleSearch}
            style={{ width: 250 }}
          />
          <RangePicker
            style={{ marginLeft: "1rem" }}
            format="YYYY-MM-DD"
            value={dateRange}
            onChange={handleDateChange}
          />
          <Button
            type="default"
            onClick={handleResetFilters}
            style={{ marginLeft: "1rem" }}
          >
            Reset Filters
          </Button>
        </div>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={generateBill}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold rounded-lg shadow-md"
        >
          Add Bill
        </Button>
      </div>

      <Table
        className="rounded-md shadow-md"
        dataSource={dataSource}
        columns={columns}
        rowKey="_id"
        pagination={{
          className: "pe-3",
          defaultPageSize: 15,
          showSizeChanger: true,
          pageSizeOptions: ["10", "20", "30", "50"],
        }}
        loading={{
          indicator: <Spin size="large" />,
          spinning: loading,
        }}
      />
    </div>
  );
};

export default BillTable;
