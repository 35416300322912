import React from "react";
import { Button, Result } from "antd";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Unhandled Error:", error, errorInfo);
  }

  handleGoBack = () => {
    window.location.replace("/dashboard");
  };

  handleReload = () => {
    window.location.reload();
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="flex justify-center items-center h-screen">
          <Result
            status="500"
            title="500"
            subTitle="Sorry, something went wrong."
            extra={
              <>
                <Button
                  onClick={this.handleGoBack}
                  className="bg-blue-500"
                  type="primary"
                >
                  Go Home
                </Button>
                <Button
                  onClick={this.handleReload}
                  className="bg-green-500"
                  type="primary"
                >
                  Reload Page
                </Button>
              </>
            }
          />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
