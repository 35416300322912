import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import LoadingScreen from "../../lib/constants/loadingScreen";
import { Table, Spin, Image, message } from "antd";
import { MdDelete, MdEditSquare } from "react-icons/md";

const ViewAdBanner = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("adminticket"),
    },
  };

  useEffect(() => {
    fetcAdBanners();
  }, []);

  const fetcAdBanners = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/ad-banners`)
      .then((response) => {
        if (response.data.success) {
          setDetails(response.data.data);
        }
      })
      .catch((e) => {
        console.error("Failed to get user details:", e);
        message.error("Failed to get user details! Please try again.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const deleteImages = async (adBannerID) => {
    if (window.confirm("Are you sure want to delete these ad-banners?")) {
      setActionLoading(true);
      try {
        const result = await axios.delete(
          `${apiBaseUrl}/ad-banner/` + adBannerID,
          config
        );
        if (result.data.success) {
          message.success("Success deletion", 0.6, function onClose() {
            fetcAdBanners();
          });
        } else {
          message.error("Failed to delete");
        }
      } catch (e) {
        console.error("Failed to delete banner:", e);
        message.error("Failed to delete banner! Please try again.");
      } finally {
        setActionLoading(false);
      }
    }
  };

  const columns = [
    {
      title: "Banner1",
      dataIndex: "bannerImage1",
      key: "bannerImage1",
      render: (bannerImage1) => {
        if (bannerImage1) {
          return (
            <Image
              src={bannerImage1}
              width={200}
              height={80}
              className="object-contain rounded-md"
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Banner2",
      dataIndex: "bannerImage2",
      key: "bannerImage2",
      render: (bannerImage2) => {
        if (bannerImage2) {
          return (
            <Image
              src={bannerImage2}
              width={200}
              height={80}
              className="object-contain rounded-md"
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Banner3",
      dataIndex: "bannerImage3",
      key: "bannerImage3",
      render: (bannerImage3) => {
        if (bannerImage3) {
          return (
            <Image
              src={bannerImage3}
              width={200}
              height={80}
              className="object-contain rounded-md"
            />
          );
        } else {
          return "-";
        }
      },
    },
    {
      title: "Banner4",
      dataIndex: "bannerImage4",
      key: "bannerImage4",
      render: (bannerImage4) => {
        if (bannerImage4) {
          return (
            <Image
              src={bannerImage4}
              width={200}
              height={80}
              className="object-contain rounded-md"
            />
          );
        } else {
          return "-";
        }
      },
    },

    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <>
          <Link
            to={`/dashboard/update/ad-banner/${record._id}`}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
              <MdEditSquare />
            </button>
          </Link>
          <Link
            onClick={() => {
              deleteImages(record._id);
            }}
            className="text-indigo-600 hover:text-indigo-900"
          >
            <button className="px-4 py-2 ms-2 rounded-md bg-red-700 text-white hover:bg-red-800">
              <MdDelete />
            </button>
          </Link>
        </>
      ),
    },
  ];

  return (
    <>
      {actionLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-900 bg-opacity-80">
          <LoadingScreen />
        </div>
      )}

      <div className="container max-w-8xl mx-auto">
        <div className="mb-4">
          <h1 className="text-3xl font-bold decoration-gray-400">Ad-Banners</h1>
          <div className="flex justify-end">
            <Link to="/dashboard/add/ad-banner">
              <button className="px-4 py-2 rounded-md bg-sky-600 text-white hover:bg-sky-700">
                Add Banner
              </button>
            </Link>
          </div>
        </div>
        <div className="flex flex-col">
          <Table
            className="rounded-md shadow-md"
            dataSource={details}
            columns={columns}
            rowKey="_id"
            pagination={{
              className: "pe-3",
              defaultPageSize: 15,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            loading={{
              indicator: <Spin size="large" />,
              spinning: loading,
            }}
          />
        </div>
      </div>
    </>
  );
};
export default ViewAdBanner;
