import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Typography,
  Divider,
  Form,
  Row,
  Image,
  Col,
  Input,
  Button,
  Alert,
  message,
  Select,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import LoadingScreen from "../lib/constants/loadingScreen";

const { Title, Text } = Typography;
const { Option } = Select;

const DoctorProfile = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [form] = Form.useForm();
  const fileInputRef = useRef(null);

  const [department, setDepartment] = useState("");
  const [DOB, setDOB] = useState("");
  const [fullname, setFullname] = useState("");
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [consultFee, setConsultFee] = useState("");
  const [avatarImage, setAvatarImage] = useState();
  const [photo, setPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);

  const isDoctor = localStorage.getItem("doctorticket");
  const isStaff = localStorage.getItem("staffticket");
  const isLabReporter = localStorage.getItem("labreporterticket");
  const isPharma = localStorage.getItem("pharmaticket");

  const doctorConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("doctorticket"),
    },
  };
  const staffConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };
  const labReporterConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("labreporterticket"),
    },
  };
  const pharmaConfig = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("pharmaticket"),
    },
  };

  useEffect(() => {
    if (isDoctor) {
      fetchDoctor();
    }
    if (isStaff) {
      fetchStaff();
    }
    if (isLabReporter) {
      fetchLabReporter();
    }
    if (isPharma) {
      fetchPharma();
    }
  }, []);

  // doctor details
  const fetchDoctor = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/doctor/profile`, doctorConfig)
      .then((response) => {
        if (response.data.success) {
          const doctor = response.data.data;
          const fetchDOB = response.data.data.DOB;
          const extractedDOB = fetchDOB?.split("T")[0];
          setRole(doctor.role);
          setFullname(doctor.fullname);
          setConsultFee(doctor.consultFee);
          setDepartment(doctor.department.department);
          form.setFieldsValue({ fullname: doctor.fullname });
          form.setFieldsValue({ phone: doctor.phone });
          form.setFieldsValue({ bio: doctor.bio });
          form.setFieldsValue({ email: doctor.email });
          form.setFieldsValue({ address: doctor.address });
          form.setFieldsValue({ gender: doctor.gender });
          form.setFieldsValue({ DOB: doctor.extractedDOB });
          setAvatarImage(doctor.picture);
          setDOB(extractedDOB);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching profile details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // staff details
  const fetchStaff = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/staff/profile`, staffConfig)
      .then((response) => {
        if (response.data.success) {
          const staff = response.data.data;
          const fetchDOB = response.data.data.DOB;
          const extractedDOB = fetchDOB?.split("T")[0];
          setRole(staff.role);
          setFullname(staff.fullname);
          setUsername(staff.username);
          form.setFieldsValue({ fullname: staff.fullname });
          form.setFieldsValue({ phone: staff.phone });
          form.setFieldsValue({ bio: staff?.bio });
          form.setFieldsValue({ email: staff.email });
          form.setFieldsValue({ address: staff.address });
          form.setFieldsValue({ gender: staff.gender });
          form.setFieldsValue({ DOB: staff.extractedDOB });
          setAvatarImage(staff.picture);
          setDOB(extractedDOB);
        } else {
          const errorMessage =
            response.data.error || "An error occurred while fetching profile.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // labreporter details
  const fetchLabReporter = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/get/labreporter/profile`, labReporterConfig)
      .then((response) => {
        if (response.data.success) {
          const labreporter = response.data.data;
          const fetchDOB = response.data.data.DOB;
          const extractedDOB = fetchDOB?.split("T")[0];
          setRole(labreporter.role);
          setFullname(labreporter.fullname);
          setUsername(labreporter.username);
          form.setFieldsValue({ fullname: labreporter.fullname });
          form.setFieldsValue({ phone: labreporter.phone });
          form.setFieldsValue({ bio: labreporter?.bio });
          form.setFieldsValue({ email: labreporter.email });
          form.setFieldsValue({ address: labreporter.address });
          form.setFieldsValue({ gender: labreporter.gender });
          form.setFieldsValue({ DOB: labreporter.extractedDOB });
          setAvatarImage(labreporter.picture);
          setDOB(extractedDOB);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching profile details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // pharma details
  const fetchPharma = () => {
    setLoading(true);
    axios
      .get(`${apiBaseUrl}/pharma/profile`, pharmaConfig)
      .then((response) => {
        if (response.data.success) {
          const pharma = response.data.data;
          const fetchDOB = response.data.data.DOB;
          const extractedDOB = fetchDOB?.split("T")[0];
          setRole(pharma.role);
          setFullname(pharma.fullname);
          setUsername(pharma.username);
          form.setFieldsValue({ fullname: pharma.fullname });
          form.setFieldsValue({ phone: pharma.phone });
          form.setFieldsValue({ bio: pharma.bio });
          form.setFieldsValue({ email: pharma.email });
          form.setFieldsValue({ address: pharma.address });
          form.setFieldsValue({ gender: pharma.gender });
          form.setFieldsValue({ DOB: pharma.extractedDOB });
          setAvatarImage(pharma.picture);
          setDOB(extractedDOB);
        } else {
          const errorMessage =
            response.data.error ||
            "An error occurred while fetching profile details.";
          message.error(errorMessage);
        }
      })
      .catch((error) => {
        console.error("Error fetching profile details.", error);
        const errorMessage =
          error.response?.data?.error ||
          error.message ||
          "An unexpected error occurred while fetching profile details.";
        message.error(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleAvatarChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setAvatarImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
      setPhoto(file);
    }
  };

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = () => {
    setActionLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        try {
          let response;
          if (isDoctor) {
            const data = new FormData();
            data.append("fullname", values.fullname);
            data.append("gender", values.gender);
            data.append("DOB", DOB);
            data.append("email", values.email);
            data.append("phone", values.phone);
            data.append("address", values.address);
            data.append("photo", photo);
            if (values.bio !== undefined) {
              data.append("bio", values.bio);
            }
            response = await axios.put(
              `${apiBaseUrl}/update/doctor/profile`,
              data,
              doctorConfig
            );
            if (response.data.success) {
              message.success("Update Success", 0.6, function onClose() {
                window.location.replace("/dashboard/profile");
              });
            } else {
              const errorMessage =
                response.data.error ||
                "An error occurred while updating the profile.";
              message.error(errorMessage);
            }
          } else if (isStaff) {
            const data = new FormData();
            data.append("fullname", values.fullname);
            data.append("gender", values.gender);
            data.append("DOB", DOB);
            data.append("email", values.email);
            data.append("phone", values.phone);
            data.append("address", values.address);
            data.append("photo", photo);
            if (values.bio !== undefined) {
              data.append("bio", values.bio);
            }
            response = await axios.put(
              `${apiBaseUrl}/update/staff/profile`,
              data,
              staffConfig
            );

            if (response.data.success) {
              message.success("Update Success");
              window.location.replace("/dashboard/profile");
            } else {
              const errorMessage =
                response.data.error ||
                "An error occurred while updating the profile.";
              message.error(errorMessage);
            }
          } else if (isLabReporter) {
            const data = new FormData();
            data.append("fullname", values.fullname);
            data.append("gender", values.gender);
            data.append("DOB", DOB);
            data.append("email", values.email);
            data.append("phone", values.phone);
            data.append("address", values.address);
            data.append("photo", photo);
            if (values.bio !== undefined) {
              data.append("bio", values.bio);
            }

            response = await axios.put(
              `${apiBaseUrl}/update/labreporter/profile`,
              data,
              labReporterConfig
            );

            if (response.data.success) {
              message.success("Update Success");
              window.location.replace("/dashboard/profile");
            } else {
              const errorMessage =
                response.data.error ||
                "An error occurred while updating the profile.";
              message.error(errorMessage);
            }
          } else if (isPharma) {
            const data = new FormData();
            data.append("fullname", values.fullname);
            data.append("gender", values.gender);
            data.append("DOB", DOB);
            data.append("email", values.email);
            data.append("phone", values.phone);
            data.append("address", values.address);
            data.append("photo", photo);
            if (values.bio !== undefined) {
              data.append("bio", values.bio);
            }

            response = await axios.put(
              `${apiBaseUrl}/update/pharma/profile`,
              data,
              pharmaConfig
            );
            if (response.data.success) {
              message.success("Update Success");
              window.location.replace("/dashboard/profile");
            } else {
              const errorMessage =
                response.data.error ||
                "An error occurred while updating the profile.";
              message.error(errorMessage);
            }
          }
        } catch (error) {
          console.error("Failed to update profile:", error);
          const errorMessage =
            error.response?.data?.error ||
            error.message ||
            "An unexpected error occurred while updating the profile.";
          message.error(errorMessage);
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  return (
    <>
      {(actionLoading || loading) && <LoadingScreen />}
      <div className="bg-gray-100 flex justify-center items-center">
        <div className="w-full">
          <div className="flex justify-center">
            <div className="w-2/3 md:mr-4">
              <Card className="shadow-lg" bordered={false}>
                <div className="flex items-center">
                  <Image
                    src={avatarImage}
                    width={120}
                    height={120}
                    className="object-contain rounded-full"
                  />
                  <div className="ml-4">
                    <Title level={3}>{fullname}</Title>
                    <div className="-mt-2">
                      <Text>{role}</Text>
                    </div>
                  </div>
                </div>
                <div className="flex items-center">
                  <Form.Item
                    // name="photos"
                    rules={[
                      { required: false, message: "Please upload an image" },
                    ]}
                  >
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleAvatarChange}
                      ref={fileInputRef}
                      className="hidden"
                    />
                    <Button
                      onClick={handleClick}
                      icon={<UploadOutlined />}
                      className="mt-2"
                    >
                      Change Avatar
                    </Button>
                  </Form.Item>
                </div>
              </Card>
              <Card className="mt-4 shadow-lg" bordered={false}>
                <div className="px-4 py-1">
                  <Title level={4}>Bio</Title>
                  <Form className="-mb-3" form={form}>
                    <Form.Item name="bio">
                      <Input.TextArea autoSize={{ minRows: 3, maxRows: 6 }} />
                    </Form.Item>
                  </Form>
                </div>
              </Card>

              <button
                id="addStaffBtn"
                className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-1.5 px-6 text-md rounded-md"
                onClick={handleSubmit}
              >
                Save Changes
              </button>
            </div>
            <div className="w-full mt-4 md:mt-0 md:ml-4">
              <Card className="shadow-lg" bordered={false}>
                <div className="p-3">
                  <Title level={4}>General Information</Title>
                  <Divider />
                  <Form layout="vertical" form={form}>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item label="Full Name" name="fullname">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Email" name="email">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item label="Phone" name="phone">
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Address" name="address">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Form.Item
                          label="Date of Birth"
                          // name="DOB"
                          rules={[
                            {
                              required: false,
                              message: "Please enter email address",
                            },
                          ]}
                        >
                          <input
                            id="DOB"
                            value={DOB}
                            type="date"
                            onChange={(e) => {
                              setDOB(e.target.value);
                            }}
                            max={new Date().toISOString().split("T")[0]}
                            className="border-solid border-gray-300 border h-8 py-2 px-2 w-full rounded-md text-gray-700 mb-3 date-picker"
                            name="dob"
                            placeholder="Select Date"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item label="Gender" name="gender">
                          <Select>
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Others">Others</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Card>
              <Card className="mt-4 shadow-lg" bordered={false}>
                <div className="p-3">
                  {isDoctor && <Title level={4}>Professional Details</Title>}
                  {(isStaff || isLabReporter || isPharma) && (
                    <Title level={4}>Other Details</Title>
                  )}
                  <Divider />
                  {isDoctor && (
                    <Form layout="vertical" form={form}>
                      <Form.Item label="Department">
                        <Input value={department} readOnly />
                      </Form.Item>
                      <Form.Item label="Consultation Fee">
                        <Input value={consultFee} readOnly prefix="Rs" />
                      </Form.Item>
                    </Form>
                  )}

                  {(isStaff || isLabReporter || isPharma) && (
                    <Form layout="vertical">
                      <Row gutter={[16, 16]}>
                        <Col span={12}>
                          <Form.Item label="Username">
                            <Input value={username} readOnly />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item label="Department">
                            <Input value={role.toUpperCase()} readOnly />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Alert
                        message="For updates on username and department details,
                        please contact the administrative section.
                        Thank you."
                        type="info"
                        showIcon
                      />
                    </Form>
                  )}
                </div>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DoctorProfile;
