import "./App.css";
import Mid from "./components/Mid";
import { BrowserRouter } from "react-router-dom";
import React from "react";
import ErrorBoundary from "./lib/constants/ErrorBoundary";

function App() {
  return (
    <>
      {/* <ErrorBoundary> */}
      <BrowserRouter>
        <Mid />
      </BrowserRouter>
      {/* </ErrorBoundary> */}
    </>
  );
}

export default App;
