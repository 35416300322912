import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import LoadingScreen from "../lib/constants/loadingScreen";
import { useParams } from "react-router-dom";
import { message, Row, Col, Typography } from "antd";
import Flatpickr from "react-flatpickr";
import "flatpickr/dist/themes/material_green.css";
import flatpickr from "flatpickr";

const { Title } = Typography;

const UpdateReferredAndFollowUpAppointmentDateTime = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const { appointmentId } = useParams();
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [patientSelectedName, setPatientSelectedName] = useState("");
  const [departmentSelectedDetails, setDepartmentSelectedDetails] = useState(
    []
  );
  const [departmentSelectedId, setDepartmentSelectedId] = useState("");
  const [departmentStoringValue, setDepartmentStoringValue] = useState(null);
  const [doctorSelectedDetails, setSelectedDoctorDetails] = useState([]);
  const [doctorSelectedId, setDoctorSelectedId] = useState("");
  const [doctorStoringValue, setDoctorStoringValue] = useState(null);
  // const [dateSelectedDetails, setSelectedDateDetails] = useState([]);
  // const [dateSelectedId, setDateSelectedId] = useState("");
  // const [dateStoringValue, setDateStoringValue] = useState(null);
  // const [timeSelectedDetails, setSelectedTimeDetails] = useState([]);
  // const [timeSelectedId, setTimeSelectedId] = useState("");
  // const [timeStoringValue, setTimeStoringValue] = useState(null);
  const [defaultDepartment, setDefaultDepartment] = useState({});
  const [defaultDoctor, setDefaultDoctor] = useState({});
  // const [defaultDate, setDefaultDate] = useState({});
  // const [defaultTime, setDefaultTime] = useState({});
  // const [previousSelectedTime, setPreviousSelectedTime] = useState("");

  const [time, setTime] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");

  const config = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("staffticket"),
    },
  };

  // fetch appointment details by id
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `${apiBaseUrl}/staff/getBookedDoctorAppointment/${appointmentId}`,
        config
      )
      .then((response) => {
        if (response.data.success) {
          setPatientSelectedName(response.data.data.fullname);
          setStatus(response.data.data.status);
          console.log(response.data.data.status);

          if (response.data.data.status === "Referred") {
            setDefaultDepartment({
              value: response.data.data.refer.toDepartment._id,
              label: response.data.data.refer.toDepartment.department,
            });
            setDepartmentSelectedId(response.data.data.refer.toDepartment._id);
            setDefaultDoctor({
              value: response.data.data.refer.toDoctor._id,
              label: response.data.data.refer.toDoctor.fullname,
            });
            console.log("jihakjsjkdh");
            setDoctorSelectedId(response.data.data.refer.toDoctor._id);
            setAppointmentDate(response.data.data.referred_appointmentDate);
            setTime(response.data.data.referred_appointmentTime);
            // setDefaultDate({
            //   value: response.data.data.referred_appointmentDate,
            //   label: response.data.data.referred_appointmentDate,
            // });
            // setDateSelectedId(response.data.data.referred_appointmentDate);
            // setDefaultTime({
            //   value: response.data.data.referred_appointmentTime,
            //   label: response.data.data.referred_appointmentTime,
            // });
            // setTimeSelectedId(response.data.data.referred_appointmentTime);
            // setPreviousSelectedTime(
            //   response.data.data.referred_appointmentTime
            // );
          } else if (response.data.data.status === "FollowUp") {
            if (
              response.data.data.refer !== undefined &&
              (response.data.data.refer?.toDoctor !== undefined ||
                response.data.data.refer?.toDoctor !== null)
            ) {
              setDefaultDepartment({
                value: response.data.data.refer.toDepartment._id,
                label: response.data.data.refer.toDepartment.department,
              });
              setDepartmentSelectedId(
                response.data.data.refer.toDepartment._id
              );
              setDefaultDoctor({
                value: response.data.data.refer.toDoctor._id,
                label: response.data.data.refer.toDoctor.fullname,
              });
              setDoctorSelectedId(response.data.data.refer.toDoctor._id);
            } else {
              setDefaultDepartment({
                value: response.data.data.department._id,
                label: response.data.data.department.department,
              });
              setDepartmentSelectedId(response.data.data.department._id);
              setDefaultDoctor({
                value: response.data.data.doctorId._id,
                label: response.data.data.doctorId.fullname,
              });
              setDoctorSelectedId(response.data.data.doctorId._id);
            }
            setAppointmentDate(response.data.data.followUp_appointmentDate);
            console.log(response.data.data.followUp_appointmentDate);
            setTime(response.data.data.followUp_appointmentTime);
            // setDefaultDate({
            //   value: response.data.data.followUp_appointmentDate,
            //   label: response.data.data.followUp_appointmentDate,
            // });
            // setDateSelectedId(response.data.data.followUp_appointmentDate);
            // setDefaultTime({
            //   value: response.data.data.followUp_appointmentTime,
            //   label: response.data.data.followUp_appointmentTime,
            // });
            // setTimeSelectedId(response.data.data.followUp_appointmentTime);
            // setPreviousSelectedTime(
            //   response.data.data.followUp_appointmentTime
            // );
          }
        }
      })
      .catch((error) => {
        console.error("Failed to get appointment detail", error);
        message.error(
          "Failed to get selected appointment detail! Please try again."
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // fetching patient details
  // useEffect(() => {
  //   axios
  //     .get(`${apiBaseUrl}/viewpatientsstaff`, config)
  //     .then((response) => {
  //       if (response.data.success) {
  //         const options = response.data.data.map((patient) => ({
  //           value: patient._id,
  //           label: patient.fullname,
  //           age: patient.age,
  //           contact: patient.phone,
  //         }));
  //         setPatientDetails(options);
  //       }
  //     })
  //     .catch((e) => {
  //       console.error("Failed to get user details:", e);
  //       message.error("Failed to get user details! Please try again.");
  //     });
  // }, []);

  // fetching department section
  useEffect(() => {
    axios
      .get(`${apiBaseUrl}/viewDepartment`)
      .then((response) => {
        if (response.data.success) {
          const options = response.data.data.map((department) => ({
            value: department._id,
            label: department.department,
          }));
          setDepartmentSelectedDetails(options);
        }
      })
      .catch((e) => {
        console.error("Failed to fetch department details:", e);
        message.error("Failed to get department details! Please try again.");
      });
  }, []);

  const handleDepartmentInputChange = (selectedDepartment) => {
    if (selectedDepartment) {
      setDepartmentStoringValue(selectedDepartment);
      setDepartmentSelectedId(selectedDepartment.value);
      setDoctorStoringValue("");
      // setDateStoringValue("");
      // setTimeStoringValue("");
    }
  };

  // Fetching doctor details based on selected department:
  useEffect(() => {
    if (departmentSelectedId) {
      axios
        .get(`${apiBaseUrl}/doctor/category/${departmentSelectedId}`)
        .then((response) => {
          if (response.data.success) {
            const options = response.data.data.map((doctor) => ({
              value: doctor._id,
              label: doctor.fullname,
            }));
            setSelectedDoctorDetails(options);
          }
        });
    }
  }, [departmentSelectedId]);

  const handleDoctorInputChange = (selectedDoctor) => {
    if (selectedDoctor) {
      setDoctorStoringValue(selectedDoctor);
      setDoctorSelectedId(selectedDoctor.value);
      // setDateStoringValue("");
      // setTimeStoringValue("");
    }
  };

  // fetch dateTime from appointmet date time
  // useEffect(() => {
  //   if (doctorSelectedId) {
  //     axios
  //       .get(`${apiBaseUrl}/staff/appointment/dateAndtime/${doctorSelectedId}`)
  //       .then((response) => {
  //         if (response.data.success) {
  //           console.log(response.data.data);
  //           const options = response.data.data.map((date) => ({
  //             value: date.date,
  //             label: date.date,
  //             time: date.time,
  //           }));
  //           setSelectedDateDetails(options);
  //         }
  //       });
  //   }
  // }, [doctorSelectedId]);

  // const handleDateInputChange = (selectedDate) => {
  //   if (selectedDate) {
  //     setDateStoringValue(selectedDate);
  //     // setDateSelectedId(selectedDate.value);
  //     setTimeStoringValue("");
  //   }
  // };

  // for reloading the data when date is selected
  // useEffect(() => {
  //   if (dateSelectedId) {
  //     const selectedDate = dateSelectedDetails.find(
  //       (date) => date.value === dateSelectedId
  //     );
  //     if (selectedDate && selectedDate.value === defaultDate.value) {
  //       const updatedTimeOptions = [
  //         ...(selectedDate.time || []).map((time) => ({
  //           value: time,
  //           label: time,
  //         })),
  //         { value: previousSelectedTime, label: previousSelectedTime },
  //       ];
  //       setSelectedTimeDetails(updatedTimeOptions);
  //     } else if (selectedDate) {
  //       setSelectedTimeDetails(
  //         selectedDate.time.map((time) => ({ value: time, label: time }))
  //       );
  //     }
  //   }
  // }, [dateSelectedId, dateSelectedDetails, defaultDate, previousSelectedTime]);

  // const handleTimeInputChange = (selectedTime) => {
  //   if (selectedTime) {
  //     setTimeSelectedId(selectedTime.value);
  //     setTimeStoringValue(selectedTime);
  //     console.log(selectedTime);
  //   }
  // };

  const createAppointment = (e) => {
    setActionLoading(true);
    e.preventDefault();

    const data = {
      status: status,
      assigningDoctorId: doctorSelectedId,
      date: appointmentDate,
      time: time,
    };

    axios
      .post(
        `${apiBaseUrl}/staff/update/appointmentdatetime/${appointmentId}`,
        data,
        config
      )
      .then((response) => {
        if (response.data.success) {
          message.success(
            "Appointment Date Time Updated Successfully",
            0.6,
            function onClose() {
              window.location.replace("/dashboard/view/inprogress/appointment");
            }
          );
        } else {
          message.error("Something went wrong! Please try again.");
        }
      })
      .catch((err) => {
        console.error("Error updating appointment:", err);
        message.error("Error updating appointment! Please try again.");
      })
      .finally(() => {
        setActionLoading(false);
      });
  };

  const handleTimeChange = (selectedDates) => {
    const selectedTime = selectedDates[0];
    const formattedTime = flatpickr.formatDate(selectedTime, "h:i K");
    setTime(formattedTime);
  };

  return (
    <div>
      {(actionLoading || loading) && <LoadingScreen />}

      <div>
        <form
          className="forbox w-full max-w-2xl bg-white h-fit max-h-lg m-auto py-10 mt-10 px-10 border rounded-lg flex flex-col gap-4"
          onSubmit={createAppointment}
        >
          <div className="-my-3 text-start">
            <Title level={3}>Update {status} Appointment DateTime</Title>
          </div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Patient Name</label>
              <input
                onChange={(e) => {
                  setPatientSelectedName(e.target.value);
                }}
                value={patientSelectedName}
                className="border-solid border-gray-300 border py-2 px-2 w-full rounded-md text-gray-700"
                name="title"
                placeholder="Fullname"
                disabled
                required
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={12}>
              <label className="text-base">Department</label>
              <Select
                options={departmentSelectedDetails}
                placeholder="Select department"
                value={
                  departmentStoringValue !== null
                    ? departmentStoringValue
                    : defaultDepartment
                }
                onChange={handleDepartmentInputChange}
                isSearchable
                isDisabled
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
            <Col span={12}>
              <label className="text-base ms-1">Doctor</label>
              <Select
                options={doctorSelectedDetails}
                value={
                  doctorStoringValue !== null
                    ? doctorStoringValue
                    : defaultDoctor
                }
                onChange={handleDoctorInputChange}
                placeholder="Select doctor"
                isSearchable
                isDisabled
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              />
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Date</label>
              <input
                id="appointmentDate"
                type="date"
                value={appointmentDate}
                onChange={(e) => {
                  setAppointmentDate(e.target.value);
                }}
                min={new Date().toISOString().split("T")[0]}
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md"
                name="appointmentDate"
                placeholder="Select Date"
              />
              {/* <Select
                options={dateSelectedDetails}
                value={
                  dateStoringValue !== null ? dateStoringValue : defaultDate
                }
                onChange={handleDateInputChange}
                placeholder="Select date"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              /> */}
            </Col>
          </Row>

          <Row gutter={[16, 16]}>
            <Col span={24}>
              <label className="text-base">Time</label>
              <Flatpickr
                data-enable-time
                data-disable-date
                placeholder="Pick a time"
                value={time}
                onChange={(selectedDates, dateStr, flatpickrInstance) =>
                  handleTimeChange(selectedDates, dateStr, flatpickrInstance)
                }
                className="border-solid border-gray-300 border py-2 px-3 w-full rounded-md transition-colors duration-200"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: "h:i K",
                  time_24hr: false,
                  allowInput: true,
                  clearIcon: true,
                }}
              />
              {/* <Select
                options={timeSelectedDetails}
                value={
                  timeStoringValue !== null ? timeStoringValue : defaultTime
                }
                onChange={handleTimeInputChange}
                placeholder="Select time"
                isSearchable
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: "0.375rem",
                  }),
                }}
              /> */}
            </Col>
          </Row>

          <button
            id="addStaffBtn"
            className="mt-4 w-full bg-sky-600 text-white hover:bg-sky-700 border shadow py-2 px-6 font-semibold text-md rounded-lg"
            type="submit"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};
export default UpdateReferredAndFollowUpAppointmentDateTime;
