import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Card, Typography, message } from "antd";
import axios from "axios";
import logo from "../components/img/tulasi_logo_login.png";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const { Option } = Select;
const { Title } = Typography;

const LoginForm = () => {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [emailOrPhone, setEmailOrPhone] = useState("");
  const [password, setPassword] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const isAuthenticated =
      localStorage.getItem("adminticket") ||
      localStorage.getItem("doctorticket") ||
      localStorage.getItem("staffticket") ||
      localStorage.getItem("labreporterticket") ||
      localStorage.getItem("pharmaticket");

    if (isAuthenticated) {
      navigate("/dashboard", { replace: true });
    }
  }, [navigate]);

  const loginUser = () => {
    setIsLoading(true);
    let data;
    let url;

    switch (selectedRole) {
      case "admin":
        data = {
          email: emailOrPhone,
          password,
        };
        url = `${apiBaseUrl}/admin/login`;
        break;
      case "doctor":
        data = {
          phone: emailOrPhone,
          password,
        };
        url = `${apiBaseUrl}/doctor/login`;
        break;
      case "staff":
        data = {
          email: emailOrPhone,
          password,
        };
        url = `${apiBaseUrl}/staff/login`;
        break;
      case "labreporter":
        data = {
          phone: emailOrPhone,
          password,
        };
        url = `${apiBaseUrl}/labreporter/login`;
        break;
      case "pharma":
        data = {
          phone: emailOrPhone,
          password,
        };
        url = `${apiBaseUrl}/pharma_staff/login`;
        break;
      default:
        message.error("Please select a role.", { position: "bottom-right" });
        return;
    }

    axios
      .post(url, data)
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem(`${selectedRole}ticket`, response.data.token);
          message.success("Login Successful", 0.6, function onClose() {
            window.location.replace("/dashboard");
          });
        } else {
          message.error("Failed to login.");
        }
      })
      .catch((error) => {
        if (error.response) {
          message.error(`Error : ${error.response.data.error}`);
        } else {
          message.error(
            "Error : An error occurred while setting up the request"
          );
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="flex justify-center items-center h-screen">
      <Card
        style={{
          width: 400,
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08)",
          borderRadius: "8px",
        }}
      >
        <div className="flex justify-center mb-8 mt-6">
          <img src={logo} alt="Logo" className="max-h-16" />
        </div>
        <div className="mb-8">
          <Title level={2} className="text-center text-gray-800 uppercase">
            Login
          </Title>
        </div>
        <Form
          form={form}
          name="login"
          onFinish={loginUser}
          initialValues={{ remember: true }}
        >
          <Form.Item
            name="role"
            label={
              <span className="text-gray-700 font-semibold">User Role</span>
            }
            rules={[{ required: true, message: "Please select a user role" }]}
          >
            <Select
              onChange={(value) => setSelectedRole(value)}
              placeholder="Select a user role"
              className="rounded-md"
            >
              <Option value="admin">Admin</Option>
              <Option value="doctor">Doctor</Option>
              <Option value="staff">Staff</Option>
              <Option value="labreporter">Lab Reporter</Option>
              <Option value="pharma">Pharmacy Staff</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="emailphone"
            label={
              selectedRole === "admin" || selectedRole === "staff" ? (
                <span className="text-gray-700 font-semibold">Email</span>
              ) : (
                <span className="text-gray-700 font-semibold">Phone</span>
              )
            }
            rules={[{ required: true, message: "Please fill this input." }]}
          >
            <Input
              placeholder={
                selectedRole === "admin" || selectedRole === "staff"
                  ? "Enter email address"
                  : "Enter phone number"
              }
              onChange={(e) => setEmailOrPhone(e.target.value)}
              className="rounded-md"
            />
          </Form.Item>

          <Form.Item
            name="password"
            label={
              <span className="text-gray-700 font-semibold">Password</span>
            }
            rules={[{ required: true, message: "Please input your password" }]}
          >
            <Input.Password
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              className="rounded-md"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!selectedRole}
              block
              className="bg-blue-600 hover:bg-blue-700 rounded-md"
              loading={isLoading}
              icon={isLoading && <LoadingOutlined spin />}
            >
              Login
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginForm;
